import React, { useState, useEffect } from "react";
import "./LegalReportForm.scss";
import { TextAreaProps, Form, TextArea, Button, Icon, Modal } from "semantic-ui-react";
import { apiLoadTheme, apiLoadMypageLegalCurriculumTheme } from "../../types";
import { useRegisterReportApi, useUpdateReportApi } from "../../api/api.hooks";
import {
  getLength,
  limitedString,
  saveLocalStorageReportEditing,
  loadLocalStageReportEditing,
  numberToBoolean,
} from "../../util/common.util";
import { CustomConfirmModal, CustomTransparentDimmer } from "../../components/Custom";
import { useUserContext } from "../../contexts/UserContext";

const LegalReportForm = (props: {
  deviceType: "PC" | "SP";
  categoryId: number | undefined;
  theme: apiLoadTheme | apiLoadMypageLegalCurriculumTheme | undefined;
  contentsId?: number;
  mode: "NEXT_ACTION" | "MYPAGE" | "GROUP_STUDY_INDIVIDUAL" | "GROUP_STUDY_INDIVIDUAL_WITH_MODAL";
  setSent?: React.Dispatch<React.SetStateAction<boolean>>;
  getNextActionFunc?: (contentdId: number, fromReport: boolean) => void;
  setGroupStudyInidividualReportFinished?: React.Dispatch<React.SetStateAction<boolean>>;
  setDisplay?: React.Dispatch<React.SetStateAction<boolean>>;
  finishedAction?: () => void;
}) => {
  const { isSaving, callRegisterReportApi } = useRegisterReportApi();
  const { isUpdating, callUpdateReportApi } = useUpdateReportApi();
  const [currentReport, setCurrentReport] = useState<string | undefined>(
    props.theme !== undefined && "report" in props.theme ? props.theme.report : ""
  );
  const [report, setReport] = useState<string | undefined>(
    props.theme !== undefined && "report" in props.theme ? props.theme.report : ""
  );
  const [sent, setSent] = useState<boolean>(false);
  const [edit, setEdit] = useState<boolean>(false);
  const [reportModal, setReportModal] = useState(true);
  const [saveConfirm, setSeaveConfirm] = useState<boolean>(false);
  const [reportFinished, setReportFinished] = useState<boolean>(
    props.theme?.reportFinished !== undefined ? props.theme.reportFinished : false
  );
  const [reportId, setReportId] = useState<number>(
    props.theme !== undefined && "reportId" in props.theme && props.theme.reportId !== undefined
      ? props.theme.reportId
      : 0
  );

  const { contextData: user } = useUserContext();

  const categoryId = props.categoryId;
  const theme = props.theme;
  const contentsId = props.contentsId;

  useEffect(() => {
    loadLocalStage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const inputReceive = (data: TextAreaProps) => {
    if (data.value) {
      const value = limitedString(String(data.value), 1500);
      setReport(value);
      if (theme) {
        saveLocalStorageReportEditing(user.accountId, user.userId, false, Number(theme.id), value);
      }
    } else {
      setReport("");
    }
  };

  const registerReport = async (themeId: number, contentsId: number | undefined, report: string) => {
    if (isSaving) {
      return;
    }
    const { isSuccess, result } = await callRegisterReportApi(false, themeId, report);
    if (isSuccess) {
      setSent(true);
      props.setSent && props.setSent(true);
      setReportId(result.reportId);
      setTimeout(() => {
        setReportModal(false);
        setSent(false);
        setEdit(false);
        setReportFinished(true);
        props.setGroupStudyInidividualReportFinished && props.setGroupStudyInidividualReportFinished(true);
        props.getNextActionFunc && contentsId !== undefined && props.getNextActionFunc(contentsId, true);
        props.finishedAction && props.finishedAction();
      }, 1500);
    }
  };

  const updateReport = async (reportId: number | undefined, report: string | undefined) => {
    if (reportId === undefined || report === undefined || isUpdating) {
      return;
    }
    if (currentReport === report) {
      setEdit(false);
      return;
    }
    const { isSuccess } = await callUpdateReportApi(false, reportId, report);
    if (isSuccess) {
      setSent(true);
      setEdit(false);
      setCurrentReport(report);
    }
  };

  const loadLocalStage = () => {
    if (theme) {
      const localStorageReport = loadLocalStageReportEditing(user.accountId, user.userId, Number(theme.id));
      if (getLength(localStorageReport) > 0) {
        setReport(localStorageReport);
      }
    }
  };

  const onClose = () => {
    setSeaveConfirm(true);
  };

  if (categoryId === undefined || !theme) {
    return <></>;
  }

  if (props.mode === "MYPAGE") {
    if (reportFinished === true) {
      return (
        <div className="legal_report_mypage">
          <div className="legal_report_header">
            <p>
              <Icon name="pencil alternate" color="grey" />
              感想レポート
            </p>
          </div>
          <div className="legal_report_body">
            {(isSaving || isUpdating) && <CustomTransparentDimmer />}
            {edit ? (
              <>
                <Form>
                  <TextArea
                    placeholder="受講感想を入力してください"
                    className="report"
                    value={report}
                    onChange={(e, data) => inputReceive(data)}
                  />
                </Form>
                <div className="report_footer">
                  <div className="input_length">
                    <p>{getLength(report).toLocaleString()}/1,500文字</p>
                  </div>
                  <div className="send_action">
                    <Button
                      content="保存"
                      color="blue"
                      size="small"
                      disabled={getLength(report) === 0 || isUpdating}
                      loading={isUpdating}
                      onClick={() => {
                        updateReport(reportId, report);
                      }}
                    />
                  </div>
                </div>
              </>
            ) : (
              <div className="legal_report_readonly">
                <p>{report}</p>
              </div>
            )}
          </div>
          <div className="legal_report_action">
            {!edit &&
              (props.theme !== undefined &&
              "reportStatus" in props.theme &&
              props.theme.reportStatus === "UNCONFIRMED" ? (
                <Button
                  content="編集"
                  size="small"
                  icon="edit"
                  onClick={() => {
                    setEdit(true);
                  }}
                />
              ) : (
                <p>※管理者確認済みのため編集できません。</p>
              ))}
          </div>
        </div>
      );
    }
    if (
      props.theme?.studyStatus === "NOT_STUDY" &&
      "themeTestPass" in props.theme &&
      !numberToBoolean(props.theme?.themeTestPass)
    ) {
      return (
        <div className="legal_report_mypage">
          <p className="title">※感想レポートはまだ提出できません</p>
        </div>
      );
    }
    if (
      props.theme?.studyStatus === "STUDYING" &&
      "themeTestPass" in props.theme &&
      !numberToBoolean(props.theme?.themeTestPass)
    ) {
      return (
        <div className="legal_report_mypage">
          <p className="title">※すべてのコンテンツが受講し終わったら感想レポートが記載できます。</p>
        </div>
      );
    }
    return (
      <div className="legal_report_mypage">
        {sent && (
          <div className="finished_message fadein">
            <div>
              <p className="finished_message_title">レポートを送信しました！</p>
            </div>
          </div>
        )}
        {(isSaving || isUpdating) && <CustomTransparentDimmer />}
        <div className="legal_report_header">
          <p>
            <Icon name="hand point right outline" />
            感想レポートを提出する
          </p>
        </div>
        <Form>
          <TextArea
            placeholder="受講感想を入力してください"
            className="report"
            value={report}
            disabled={sent}
            onChange={(e, data) => inputReceive(data)}
          />
        </Form>
        <div className="report_footer">
          <div className="input_length">
            <p>{getLength(report).toLocaleString()}/1,500文字</p>
          </div>
          <div className="send_action">
            <Button
              content="レポートを提出する"
              color="blue"
              size="small"
              disabled={getLength(report) === 0 || isSaving}
              loading={isSaving}
              onClick={() => {
                registerReport(theme.id, contentsId, report as string);
              }}
            />
          </div>
        </div>
      </div>
    );
  }

  if (props.mode === "GROUP_STUDY_INDIVIDUAL" || props.mode === "GROUP_STUDY_INDIVIDUAL_WITH_MODAL") {
    if (
      props.theme?.studyStatus === "NOT_STUDY" &&
      "themeTestPass" in props.theme &&
      !numberToBoolean(props.theme?.themeTestPass)
    ) {
      return (
        <div className="legal_report_group_study_individual">
          <p className="title">※感想レポートはまだ提出できません</p>
        </div>
      );
    }
    if (
      props.theme?.studyStatus === "STUDYING" &&
      "themeTestPass" in props.theme &&
      !numberToBoolean(props.theme?.themeTestPass)
    ) {
      return (
        <div className="legal_report_group_study_individual">
          <p className="title">※すべてのコンテンツが受講し終わったら感想レポートが記載できます。</p>
        </div>
      );
    }
    if (props.mode === "GROUP_STUDY_INDIVIDUAL") {
      return (
        <div className="legal_report_group_study_individual">
          {(isSaving || isUpdating) && <CustomTransparentDimmer />}
          <>
            <Form>
              <TextArea
                placeholder="受講感想を入力してください"
                className="report"
                value={report}
                disabled={sent}
                onChange={(e, data) => inputReceive(data)}
              />
            </Form>
            <div className="report_footer">
              <div className="input_length">
                <p>
                  <span className="input_finished">{getLength(report).toLocaleString()}</span> / 1,500文字
                </p>
              </div>
              <div className="send_action">
                <Button
                  content="レポートを提出する"
                  className="send_button"
                  size="small"
                  disabled={getLength(report) === 0 || isSaving}
                  loading={isSaving}
                  onClick={() => {
                    registerReport(theme.id, contentsId, report as string);
                  }}
                />
              </div>
            </div>
          </>
        </div>
      );
    }
    if (props.mode === "GROUP_STUDY_INDIVIDUAL_WITH_MODAL") {
      return (
        <Modal open={reportModal} className="legal_report_form_individual_modal" size="small" onClose={onClose}>
          <div className="legal_report_group_study_individual">
            {(isSaving || isUpdating) && <CustomTransparentDimmer />}
            {sent ? (
              <div className="finished_message fadein">
                <div>
                  <p className="finished_message_title">レポートを送信しました！</p>
                </div>
              </div>
            ) : (
              <>
                <p className="theme_title">{theme.name}の感想レポートを提出しましょう！</p>
                <Form>
                  <TextArea
                    placeholder="受講感想を入力してください"
                    className="report"
                    value={report}
                    disabled={sent}
                    onChange={(e, data) => inputReceive(data)}
                  />
                </Form>
                <div className="report_footer">
                  <div className="input_length">
                    <p>
                      <span className="input_finished">{getLength(report).toLocaleString()}</span> / 1,500文字
                    </p>
                  </div>
                  <div className="send_action">
                    <Button
                      content="レポートを提出する"
                      className="send_button"
                      size="small"
                      disabled={getLength(report) === 0 || isSaving}
                      loading={isSaving}
                      onClick={() => {
                        registerReport(theme.id, contentsId, report as string);
                      }}
                    />
                    <Button
                      content="閉じる"
                      className="close_button"
                      size="small"
                      loading={isSaving}
                      onClick={() => {
                        props.setDisplay && props.setDisplay(false);
                      }}
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </Modal>
      );
    }
  }

  return (
    <>
      <Modal open={reportModal} className="legal_report_form_modal" size="small" closeIcon={true} onClose={onClose}>
        <Modal.Content className="legal_report_form_modal_content">
          <CustomConfirmModal
            open={saveConfirm}
            close={setSeaveConfirm}
            headerText="確認"
            bodyText={
              <div className="confirm_message">
                <p>レポートを提出していませんが閉じてよろしいですか？</p>
              </div>
            }
            actionsChildren={
              <>
                <Button content="戻る" positive onClick={() => setSeaveConfirm(false)} />
                <Button content="提出しない" negative onClick={() => setReportModal(false)} />
              </>
            }
          />
          <div className="legal_report_form">
            {sent && (
              <div className="finished_message fadein">
                <div>
                  <p className="finished_message_title">レポートを送信しました！</p>
                </div>
              </div>
            )}
            {isSaving && <CustomTransparentDimmer />}
            <p className="theme_title">「{theme.name}」の受講を完了しました！研修の感想レポートを提出しましょう。</p>
            <Form>
              <TextArea
                placeholder="受講感想を入力してください"
                className="report"
                value={report}
                disabled={sent}
                onChange={(e, data) => inputReceive(data)}
              />
            </Form>
            <div className="report_footer">
              <div className="input_length">
                <p>{getLength(report).toLocaleString()}/1,500文字</p>
              </div>
              <div className="send_action">
                <Button
                  content="レポートを提出する"
                  color="blue"
                  size="large"
                  disabled={getLength(report) === 0}
                  loading={isSaving}
                  onClick={() => {
                    registerReport(theme.id, contentsId, report as string);
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Content>
      </Modal>
      <div className="legal_report_form">
        {sent && (
          <div className="finished_message fadein">
            <div>
              <p className="finished_message_title">レポートを送信しました！</p>
            </div>
          </div>
        )}
        {isSaving && <CustomTransparentDimmer />}
        <p className="report_title">
          {props.deviceType === "SP" && <Icon name="hand point right outline" />}
          レポートを提出する
        </p>
        <p className="theme_title">「{theme.name}」の受講を完了しました。研修の感想レポートを提出してください。</p>
        <Form>
          <TextArea
            placeholder="受講感想を入力してください"
            className="report"
            value={report}
            disabled={sent}
            onChange={(e, data) => inputReceive(data)}
          />
        </Form>
        <div className="report_footer">
          <div className="input_length">
            <p>{getLength(report).toLocaleString()}/1,500文字</p>
          </div>
          <div className="send_action">
            <Button
              content="レポートを提出する"
              color="blue"
              size="large"
              disabled={getLength(report) === 0 || isSaving}
              loading={isSaving}
              onClick={() => {
                registerReport(theme.id, contentsId, report as string);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalReportForm;
