import { flowType, otpType } from "../types";
import { getCookieToken } from "../util/common.util";
import { registerFrontAuthLog } from "./repository";

// hookの必要がないapiはこちらで配置
// 例えばbannerの表示がいらない、非同期で走らせる処理などを定義する

export const registerFrontAuthLogApi = (info: {
  userId?: string;
  accountId?: string;
  message: string;
  options?: unknown;
}) => {
  const getValueOrDefault = (value: string | undefined) => {
    if (value) {
      return value == "" ? "NOT_SET" : value;
    }
    return "NOT_SET";
  };

  registerFrontAuthLog(getValueOrDefault(info.userId), getValueOrDefault(info.accountId), info.message, info.options);
};

export const registerLoginFailedLogApi = (info: {
  loginType: "PASSWORD" | "OTP-EMAIL" | "OTP-SMS";
  flowType: flowType;
  options: {
    userId?: string;
    accountId?: string;
    password?: string;
    email?: string;
    phoneNumber?: string;
    otpCode?: string;
  };
}) => {
  registerFrontAuthLogApi({
    message: `LOGIN-FAILED-COUNT ${info.loginType} ${info.flowType}`,
    options: info.options,
  });
};

export const registerSendOtpLogApi = (info: {
  type: otpType;
  flowType: flowType;
  email?: string;
  phoneNumber?: string;
}) => {
  registerFrontAuthLogApi({
    message: `SEND-OTP-COUNT ${info.type} ${info.flowType} ${info.email || info.phoneNumber}`,
    options: info,
  });
};

export const registerCookieLogApi = (info: object) => {
  registerFrontAuthLogApi({
    message: `SEND-CHANGE-COOKIE`,
    options: {
      ...info,
      documentCookie: document.cookie,
      tokens: getCookieToken(),
    },
  });
};
