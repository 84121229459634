import React, { createContext, useReducer, useContext } from "react";
import { account, apiUserInfo, childrenProps } from "../types";
import { encryptedString, setCookieUserToken, setCurrentAccountStorage } from "./../util/common.util";

export type UserInfo = apiUserInfo & {
  currentAccount?: account;
  token: string;
};

const initialState = {} as UserInfo;

const UserContext = createContext(
  {} as {
    contextData: UserInfo;
    dispatch: React.Dispatch<action>;
  }
);

type actionType = "SET_USER" | "SET_CURRENT_ACCOUNT" | "SET_INIT";
type action = {
  type: actionType;
  user?: UserInfo;
  account?: account;
};

export const setUser = (user: UserInfo): action => {
  return { type: "SET_USER", user };
};

export const setCurrentAccount = (account: account, userId: string): action => {
  setCurrentAccountStorage(account, userId);
  return { type: "SET_CURRENT_ACCOUNT", account };
};

export const setInit = (): action => {
  return { type: "SET_INIT" };
};

const convertUserToken = (accountId: string, userId: string, currentAccountId: string): string => {
  return encryptedString(JSON.stringify({ accountId, userId, currentAccountId }));
};

const userReducer = (state: UserInfo, action: action): UserInfo => {
  switch (action.type) {
    case "SET_USER":
      if (!action.user) return state;
      {
        const currentAccount = action.user.currentAccount || state.currentAccount;
        const token = convertUserToken(action.user.accountId, action.user.userId, currentAccount?.id || "");
        setCookieUserToken(token);
        return { ...action.user, token: token, currentAccount };
      }
    case "SET_CURRENT_ACCOUNT":
      if (!action.account) return state;
      {
        const token = convertUserToken(state.accountId, state.userId, action.account.id);
        setCookieUserToken(token);
        return {
          ...state,
          currentAccount: action.account,
          token: token,
        };
      }
    case "SET_INIT":
      return initialState;
    default:
      return state;
  }
};

export const UserProvider = (props: childrenProps) => {
  const [contextData, dispatch] = useReducer(userReducer, initialState);
  const data = { contextData, dispatch };
  return <UserContext.Provider value={data} {...props} />;
};

export const useUserContext = () => {
  return useContext(UserContext);
};
