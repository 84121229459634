import axios from "axios";

export const uploadS3Api = (props: {
  uploadPreSignedURL: string;
  file: File;
  contentType: string;
  progress?: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { uploadPreSignedURL, file, contentType, progress } = props;
  const options = {
    headers: {
      "Content-Type": contentType,
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onUploadProgress: function (progressEvent: any) {
      const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      if (progress !== undefined) {
        progress(percentCompleted);
      }
    },
  };
  return axios.put(uploadPreSignedURL, file, options);
};
