import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import ja from "dayjs/locale/ja";
import {
  apiLoadTestItem,
  userType,
  answerTemplateType,
  correctAnswerType,
  customListFromType,
  testStatus,
  monthEnName,
  account,
  cloudFrontCookie,
  apiLoadNextAction,
  otpType,
  inputUserValueType,
  userAdditionalInfoInputType,
  flowType,
  loginType,
  playerOptionsType,
  inquiryType,
  inquiryDetailType,
  mypageTabName,
  accountPlanType,
  accountPlanCode,
  apiLoadGroupStudiedUser,
  deviceType,
} from "../types";
import { isMobile } from "react-device-detect";
import ExcelJS from "exceljs";
import encoding from "encoding-japanese";
import userDefaultImage from "../assets/images/user_default_100.png";
import userSupportImage from "../assets/images/circle-yagimaro.png";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.tz.setDefault("Asia/Tokyo");
dayjs.locale(ja);
import Cookies, { CookieChangeOptions, CookieSetOptions } from "universal-cookie";
import {
  TOKEN_COOKIE_NAME,
  REFRESH_COOKIE_NAME,
  APP_DOMAIN,
  COOKIE_DOMAIN,
  USER_COOKIE_NAME,
  ROUTE_PATH,
  DEPLOY_DATE_COOKIE_NAME,
  levelColorCodes,
  CLOUD_FRONT_COOKIES,
  MEDIA_COOKIE_DOMAIN,
  MEDIA_COOKIE_ENV,
  MAIL_ADDRESS_INPUT,
  QUESTION_SUPPORT_SYSTEM_USER,
  LOGIN_SESSION_INFO,
  OTP_SESSION_INFO,
  mailAddressRegexp,
  subtitlelanguageOptions,
  IS_AUTO_MIGRATE,
  VALIDATIION_MESSAGE,
  phoneNumberRegexp,
} from "../static";
import { createWriteStream } from "streamsaver";
import { Buffer } from "buffer";
import { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { registerCookieLogApi } from "../api/api.util";

const cookies = new Cookies();

const WATCH_TAGET_COOKIE_NAMES = [TOKEN_COOKIE_NAME, REFRESH_COOKIE_NAME, USER_COOKIE_NAME, DEPLOY_DATE_COOKIE_NAME];
const watchCookieChangeLisner = (options: CookieChangeOptions) => {
  if (WATCH_TAGET_COOKIE_NAMES.includes(options.name) || options.name.includes("auth0")) {
    registerCookieLogApi(options);
  }
};

export const startChangeCookieLisner = () => {
  cookies.addChangeListener(watchCookieChangeLisner);
};

export const stopChangeCookieLisner = () => {
  cookies.removeChangeListener(watchCookieChangeLisner);
};

export const replaceIdKeyFromRoutePath = (ids: object, routePath: string) => {
  let replacedRoutePath = routePath;
  const arrayIds = Object.entries(ids);
  arrayIds.forEach((item) => {
    const unionRegex = new RegExp(`:${item[0]}`, "g");
    replacedRoutePath = replacedRoutePath.replace(unionRegex, item[1] as string);
  });
  return replacedRoutePath;
};

export const getThemePagePath = (categoryId: number, themeId: number, accountOriginalFlg?: boolean): string => {
  const params = { categoryId, themeId };
  return accountOriginalFlg
    ? replaceIdKeyFromRoutePath(params, ROUTE_PATH.THEME_ORIGINAL)
    : replaceIdKeyFromRoutePath(params, ROUTE_PATH.THEME);
};

export const getContentPagePath = (contentsId: number, accountOriginalFlg?: boolean): string => {
  return accountOriginalFlg
    ? replaceIdKeyFromRoutePath({ contentsId: contentsId }, ROUTE_PATH.CONTENTS_ORIGINAL)
    : replaceIdKeyFromRoutePath({ contentsId: contentsId }, ROUTE_PATH.CONTENTS);
};

export const getContentTestPagePath = (contentsId: number, accountOriginalFlg?: boolean): string => {
  return accountOriginalFlg
    ? replaceIdKeyFromRoutePath({ contentsId: contentsId }, ROUTE_PATH.CONTENTS_TEST_ORIGINAL)
    : replaceIdKeyFromRoutePath({ contentsId: contentsId }, ROUTE_PATH.CONTENTS_TEST);
};

export const getDrillDownPagePath = (from: customListFromType): string => {
  const params = { from };
  return replaceIdKeyFromRoutePath(params, ROUTE_PATH.CUSTOM_LIST);
};

export const textOverflow = (str: string | undefined, length: number) => {
  if (str === undefined) return "";
  if (getLength(str) > length && length !== undefined) {
    return `${str.substring(0, length)}...`;
  } else {
    return str;
  }
};

export const getLength = (str: string | undefined) => {
  if (str !== undefined) {
    str = str == null ? "" : str;
    return str.length;
  }
  return 0;
};

export const getDateTimeString = (date: Date | undefined, formatType?: "short") => {
  if (date && date !== undefined) {
    // APIからの日付がすでにJSTのため-9時間する
    return dayjs(date)
      .subtract(9, "hours")
      .format(formatType === "short" ? "YYYY/MM/DD HH:mm:ss" : "YYYY年MM月DD日 HH:mm:ss");
  }
  return "";
};

export const getDateString = (date: Date | undefined, unchangeTimeZone?: boolean, formatType?: "short") => {
  if (date && date !== undefined) {
    if (unchangeTimeZone) {
      return dayjs(date).format("YYYY年MM月DD日");
    } else {
      // APIからの日付がすでにJSTのため-9時間する
      return dayjs(date)
        .subtract(9, "hours")
        .format(formatType === "short" ? "YYYY/MM/DD" : "YYYY年MM月DD日");
    }
  }
  return "";
};

export const getDateWithDayOfWeekString = (date: Date | undefined, unchangeTimeZone?: boolean) => {
  if (date && date !== undefined) {
    if (unchangeTimeZone) {
      return dayjs(date).format("YYYY年MM月DD日(dd)");
    } else {
      // APIからの日付がすでにJSTのため-9時間する
      return dayjs(date).subtract(9, "hours").format("YYYY年MM月DD日(dd)");
    }
  }
  return "";
};

export const getDateFromatedString = (date: Date | null) => {
  if (date && date !== undefined && date !== null) {
    return dayjs(date).format("YYYY-MM-DD");
  }
  return null;
};

export const getDateApiFromatedString = (date: Date | null, format: string = "YYYY-MM-DD") => {
  if (date && date !== undefined) {
    // APIからの日付がすでにJSTのため-9時間する
    return dayjs(date).subtract(9, "hours").format(format);
  }
  return null;
};

export const getOriginalDateTimeString = (date: Date, format: string) => {
  return dayjs(date).format(format);
};

export const getDateTimeOnlyNumberString = (date: Date) => {
  return dayjs(date).subtract(9, "hours").format("YYYYMMDDHHmmss");
};

export const getJstDateApi = (date: Date | undefined) => {
  if (date && date !== undefined) {
    // APIからの日付がすでにJSTのため-9時間する
    return dayjs(date).subtract(9, "hours");
  }
  return "";
};

export const calcDayAgo = (serverDate: Date) => {
  const now = dayjs().startOf("day");
  // APIからの日付がすでにJSTのため-9時間する
  const targetDate = dayjs(serverDate).subtract(9, "hours").startOf("day");
  return now.diff(targetDate, "d");
};

// 半角英数記号のみ
export const onlyAlphaNumericSymbole = {
  pattern: "^[0-9A-Za-z@._%+-]+$",
  title: "半角英数および記号@._%+-のみ",
};

export const getOnlyAlphaNumericSymbole = (value: string) => {
  return value.replace(/[^0-9A-Za-z@._%+-]/g, "");
};

export const getOnlyAllowIdAlphaNumericSymbole = (value: string) => {
  return (
    value
      .replace(/[^0-9A-Za-z._+-]/g, "")
      // 先頭の.だけ削除 ※ダミーメールアドレスの際に.が先頭になりエラーになるのを防ぐため
      .replace(/^\.+/, "")
  );
};

// 半角数字のみ
export const onlyNumberSymbole = {
  pattern: "^[0-9]+$",
  title: "ハイフンなし番号のみ",
};

export const getOnlyNumeric = (value: string) => {
  return value.replace(/[^0-9]/g, "");
};

export const getOnlyNumber = (value: string) => {
  return Number(value.replace(/[^0-9]/g, ""));
};

export const undefinedToNumber = (value: number | undefined, initialValue: number) => {
  if (value !== undefined) {
    return value;
  }
  return initialValue;
};

export const undefinedToString = (value: string | undefined, initialValue: string) => {
  if (value !== undefined) {
    return value;
  }
  return initialValue;
};

export const undefinedToBoolean = (value: boolean | undefined, initialValue: boolean) => {
  if (value !== undefined) {
    return value;
  }
  return initialValue;
};

export const getCorrectCount = (testItems: apiLoadTestItem[]) => {
  const correctCount = testItems.filter((item) => item.correct).length;
  return {
    questionCount: testItems.length,
    correctCount: correctCount,
  };
};

export const getDurationString = (seconds: number | undefined) => {
  if (seconds !== undefined) {
    const format = (() => {
      if (seconds < 3600) {
        return "mm:ss";
      }
      return "HH:mm:ss";
    })();
    const sec = dayjs().subtract(seconds, "seconds");
    const dur = dayjs.duration(dayjs().diff(sec));
    return dayjs.utc(dur.asMilliseconds()).format(format);
  }
  return "";
};

export const getDurationJpString = (seconds: number | undefined, useSeconds: boolean) => {
  if (seconds !== undefined) {
    const format = (() => {
      if (seconds < 3600) {
        if (useSeconds) {
          return "mm分ss秒";
        } else {
          return "mm分";
        }
      }
      if (useSeconds) {
        return "HH時間mm分ss秒";
      } else {
        return "HH時間mm分";
      }
    })();
    const sec = dayjs().subtract(seconds, "seconds");
    const dur = dayjs.duration(dayjs().diff(sec));
    return dayjs.utc(dur.asMilliseconds()).format(format);
  }
  return "";
};

export const setSiteTitle = (title: string | null) => {
  if (title && getLength(title) > 0) {
    document.title = `DM Study | ${title}`;
    return;
  }
  document.title = "DM Study";
};

export const moveScrollTop = () => {
  document.getElementById("pc-content")?.scrollIntoView();
  document.getElementById("pc-banner")?.scrollIntoView();
  document.getElementById("sp-main")?.scrollIntoView();
};

export const getCookieToken = () => {
  return cookies.get(TOKEN_COOKIE_NAME);
};

export const getCookieRefreshToken = () => {
  return cookies.get(REFRESH_COOKIE_NAME);
};

export const getCookieUserToken = () => {
  return cookies.get(USER_COOKIE_NAME);
};
export const setCookieUserToken = (userToken: string) => {
  const now = new Date();
  const cookieConfig: CookieSetOptions = {
    domain: COOKIE_DOMAIN,
    sameSite: "strict",
    secure: true,
    path: "/",
    // 期限は現在から1年後とする
    expires: new Date(now.setFullYear(now.getFullYear() + 1)),
  };

  cookies.set(USER_COOKIE_NAME, userToken, cookieConfig);
  return cookies.get(USER_COOKIE_NAME);
};

export const getCookieMailAddressInput = () => {
  return cookies.get(MAIL_ADDRESS_INPUT);
};

export const setTokenCookie = (
  accessToken: string,
  // auth0からのexpiresInは秒単位で何秒後にきれるかがくる
  expiresIn: number,
  // groupStudyログインの場合は直接期限のunixtimeがくるため、そのまま使う
  isGroupStudy?: boolean
) => {
  const expiration = isGroupStudy
    ? new Date(expiresIn * 1000)
    : dayjs().add(expiresIn, "seconds").subtract(15, "minute").toDate();

  const cookieConfig: CookieSetOptions = {
    domain: COOKIE_DOMAIN,
    sameSite: "strict",
    secure: true,
    path: "/",
    // apiアクセス前にトークン期限切れをとるため、本来の期限より15分早くcookieからは消えるよう設定
    expires: expiration,
  };
  cookies.set(TOKEN_COOKIE_NAME, `Bearer ${accessToken}`, cookieConfig);
};

export const getDeployDateCookie = () => {
  return cookies.get(DEPLOY_DATE_COOKIE_NAME);
};

export const setDeployDateCookie = (deployDate: string) => {
  const now = new Date();
  const cookieConfig: CookieSetOptions = {
    domain: COOKIE_DOMAIN,
    sameSite: "strict",
    secure: true,
    path: "/",
    // 無期限有効とする
    expires: new Date(now.setFullYear(now.getFullYear() + 100)),
  };
  cookies.set(DEPLOY_DATE_COOKIE_NAME, deployDate, cookieConfig);
};

export const setMediaOriginalCookie = (mediaOriginalCFCookies: cloudFrontCookie) => {
  if (!mediaOriginalCFCookies) {
    return;
  }
  const cookieConfig: CookieSetOptions = {
    domain: COOKIE_DOMAIN,
    sameSite: "strict",
    secure: true,
    path: "/",
  };

  cookies.set(
    addEnvSuffix(CLOUD_FRONT_COOKIES.KEY_PAIR, MEDIA_COOKIE_ENV),
    mediaOriginalCFCookies.keyPairId,
    cookieConfig
  );
  cookies.set(
    addEnvSuffix(CLOUD_FRONT_COOKIES.SIGNATURE, MEDIA_COOKIE_ENV),
    mediaOriginalCFCookies.signature,
    cookieConfig
  );
  cookies.set(addEnvSuffix(CLOUD_FRONT_COOKIES.POLICY, MEDIA_COOKIE_ENV), mediaOriginalCFCookies.policy, cookieConfig);
};

// 現在の操作環境のcookieをcloudFrontの署名付cookieとして設定する
export const setCurrentEnvMediaOriginalCookie = () => {
  const mediaOriginalCFCookies = {
    keyPairId: cookies.get(addEnvSuffix(CLOUD_FRONT_COOKIES.KEY_PAIR, MEDIA_COOKIE_ENV)) as string,
    signature: cookies.get(addEnvSuffix(CLOUD_FRONT_COOKIES.SIGNATURE, MEDIA_COOKIE_ENV)) as string,
    policy: cookies.get(addEnvSuffix(CLOUD_FRONT_COOKIES.POLICY, MEDIA_COOKIE_ENV)) as string,
  };

  const cookieConfig: CookieSetOptions = {
    domain: MEDIA_COOKIE_DOMAIN,
    sameSite: "strict",
    secure: true,
    path: "/",
  };

  cookies.set(CLOUD_FRONT_COOKIES.KEY_PAIR, mediaOriginalCFCookies.keyPairId, cookieConfig);
  cookies.set(CLOUD_FRONT_COOKIES.SIGNATURE, mediaOriginalCFCookies.signature, cookieConfig);
  cookies.set(CLOUD_FRONT_COOKIES.POLICY, mediaOriginalCFCookies.policy, cookieConfig);
};

export const setMailAddressInputCookie = (mailAddressInputUserList: string) => {
  const now = new Date();
  const cookieConfig: CookieSetOptions = {
    domain: COOKIE_DOMAIN,
    sameSite: "strict",
    secure: true,
    path: "/",
    // 無期限有効とする
    expires: new Date(now.setFullYear(now.getFullYear() + 100)),
  };
  cookies.set(MAIL_ADDRESS_INPUT, mailAddressInputUserList, cookieConfig);
};

const addEnvSuffix = (key: string, env: string) => {
  return `${key}_${env}`;
};

export const getCurrentAccountStorage = (): account | null => {
  const account = localStorage.getItem("currentAccount");
  return account ? JSON.parse(account) : null;
};

export const setCurrentAccountStorage = (account: account, userId: string) => {
  localStorage.setItem("currentAccount", JSON.stringify(account));

  const prvAccunts = getLatestUserSelectAccountsStorage(userId).filter((item) => item.id !== account.id);
  const accounts = prvAccunts.length > 4 ? [account, ...prvAccunts.slice(0, 3)] : [account, ...prvAccunts];
  localStorage.setItem("latestUserSelectAccounts", JSON.stringify({ userId, accounts }));
};

export const clearCurrentAccountStorage = () => {
  localStorage.removeItem("currentAccount");
};

export const getLatestUserSelectAccountsStorage = (userId: string): account[] => {
  const latestUserSelectAccountsStr = localStorage.getItem("latestUserSelectAccounts");
  if (!latestUserSelectAccountsStr) return [];
  const { userId: cacheUserId, accounts } = JSON.parse(latestUserSelectAccountsStr);
  return cacheUserId == userId ? accounts : [];
};

export const clearAuthCookie = () => {
  /** Cannot delete the parent domain cookie,
   *  So instead, set the cookie to expired.
   *  expires = Date now - 30 days
   */
  const now = new Date();
  now.setDate(now.getDate() - 30);
  cookies.set(TOKEN_COOKIE_NAME, "", {
    expires: now,
    domain: COOKIE_DOMAIN,
    sameSite: "strict",
    secure: true,
    path: "/",
  });

  cookies.set(REFRESH_COOKIE_NAME, "", {
    expires: now,
    domain: COOKIE_DOMAIN,
    sameSite: "strict",
    secure: true,
    path: "/",
  });

  cookies.set(USER_COOKIE_NAME, "", {
    expires: now,
    domain: COOKIE_DOMAIN,
    sameSite: "strict",
    secure: true,
    path: "/",
  });
  // cloudFrontアクセス用のcookieを削除
  cookies.set(CLOUD_FRONT_COOKIES.KEY_PAIR, "", {
    expires: now,
    domain: MEDIA_COOKIE_DOMAIN,
    sameSite: "strict",
    secure: true,
    path: "/",
  });
  cookies.set(CLOUD_FRONT_COOKIES.SIGNATURE, "", {
    expires: now,
    domain: MEDIA_COOKIE_DOMAIN,
    sameSite: "strict",
    secure: true,
    path: "/",
  });
  cookies.set(CLOUD_FRONT_COOKIES.POLICY, "", {
    expires: now,
    domain: MEDIA_COOKIE_DOMAIN,
    sameSite: "strict",
    secure: true,
    path: "/",
  });
  // cloudFrontアクセス用の環境別cookieを削除
  cookies.set(addEnvSuffix(CLOUD_FRONT_COOKIES.KEY_PAIR, MEDIA_COOKIE_ENV), "", {
    expires: now,
    domain: COOKIE_DOMAIN,
    sameSite: "strict",
    secure: true,
    path: "/",
  });
  cookies.set(addEnvSuffix(CLOUD_FRONT_COOKIES.SIGNATURE, MEDIA_COOKIE_ENV), "", {
    expires: now,
    domain: COOKIE_DOMAIN,
    sameSite: "strict",
    secure: true,
    path: "/",
  });
  cookies.set(addEnvSuffix(CLOUD_FRONT_COOKIES.POLICY, MEDIA_COOKIE_ENV), "", {
    expires: now,
    domain: COOKIE_DOMAIN,
    sameSite: "strict",
    secure: true,
    path: "/",
  });
};

export const getTokenInfo = () => {
  return {
    accessToken: getCookieToken(),
    refreshToken: getCookieRefreshToken(),
    userToken: getCookieUserToken(),
  };
};

export const isSameToken = (userToken: string) => {
  return getCookieToken() === userToken;
};

export const limitedString = (string: string, length: number) => {
  const REPLACE_TAB = /\t/g;
  // eslint-disable-next-line no-control-regex
  const REPLACE_UNICODE = /[\u{0000}-\u{0009}]+|[\u{000b}-\u{000c}]+|[\u{000e}-\u{001f}]/gu;
  return string.substring(0, length).replace(REPLACE_TAB, "").replace(REPLACE_UNICODE, "");
};

export const isDisabledTest = (
  historyContentsStatus: string | undefined,
  viewCount: number | undefined,
  requiredTest: boolean | undefined
) => {
  if (!requiredTest) {
    return true;
  }
  if (historyContentsStatus !== undefined && viewCount !== undefined) {
    if (viewCount > 1 || (historyContentsStatus === "VIEWED" && viewCount === 1)) {
      return false;
    }
  }
  return true;
};

export const numberToBoolean = (value: number | boolean | undefined) => {
  if (value === 1 || value === true) {
    return true;
  }
  return false;
};

export const booleanToNumber = (value: boolean | undefined) => {
  if (value) {
    return 1;
  }
  return 0;
};

export const getMaxQuery = () => {
  if (isMobile) {
    return "(max-width: 1023px)";
  } else {
    return "(max-width: 766px)";
  }
};

export const getMinQuery = () => {
  if (isMobile) {
    return "(min-width: 1024px)";
  } else {
    return "(min-width: 767px)";
  }
};

export const getUserImage = (imageUrl: string, userId: number) => {
  if (userId === QUESTION_SUPPORT_SYSTEM_USER.ID) {
    return userSupportImage;
  }
  if (!imageUrl || getLength(imageUrl) === 0) {
    return userDefaultImage;
  }
  return imageUrl;
};
export const getUserImageBorderClass = (userId: number) => {
  if (userId === QUESTION_SUPPORT_SYSTEM_USER.ID) {
    return "img-circle-border";
  }
  return "";
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkBlankValidation = (keys: string[], values: any) => {
  const errorKeys = [];
  keys.forEach((key) => {
    if (getLength(values[key]) === 0) {
      errorKeys.push(key);
    }
  });
  if (errorKeys.length === 0) {
    return true;
  }
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const checkUnselectValidation = (keys: string[], values: any) => {
  const errorKeys = [];
  keys.forEach((key) => {
    if (values[key] === 0) {
      errorKeys.push(key);
    }
  });
  if (errorKeys.length === 0) {
    return true;
  }
  return false;
};

export const getUserTypeText = (type: userType) => {
  switch (type) {
    case "USER":
      return "ユーザー";
    case "ACCOUNT_ADMIN":
      return "施設管理者";
    case "CORPORATION_ADMIN":
      return "法人管理者";
    case "DM_ADMIN":
      return "DM管理者";
  }
};

export const getAnswerTemplateTypeText = (type: answerTemplateType) => {
  switch (type) {
    case "TEXT":
      return "テキスト";
    case "IMAGE":
      return "画像";
    case "TEXT_IMAGE":
      return "テキスト＋画像";
  }
};

export const getCorrectAnswerTypeText = (type: correctAnswerType) => {
  switch (type) {
    case "SIMPLE":
      return "単一選択";
    case "MULTI":
      return "複数選択";
  }
};

export const getCommonAndOriginalId = (
  accountOriginalFlg: boolean,
  type: "category" | "career",
  id: number | string
) => {
  if (accountOriginalFlg) {
    return `o-${id}`;
  }
  if (type === "category") {
    return `c-${id}`;
  } else {
    return `l-${id}`;
  }
};

export const getCommonAndOriginalParams = (menuId: string | undefined) => {
  if (menuId !== undefined) {
    const menu = menuId.split("-");
    if (menu[0] === "c") {
      return { accountOriginalFlg: false, id: Number(menu[1]), type: "category" };
    } else if (menu[0] === "l") {
      return { accountOriginalFlg: false, id: Number(menu[1]), type: "career" };
    }
    return { accountOriginalFlg: true, id: Number(menu[1]), type: "category" };
  }
  return { accountOriginalFlg: false, id: 0 };
};

export const exportCsvFromArray = async (
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  fileData: { fileName: string; header: { key: string; header: string }[]; body: object[] }
) => {
  e.preventDefault();

  const workbook = new ExcelJS.Workbook();
  workbook.addWorksheet("sheet1");
  const worksheet = workbook.getWorksheet("sheet1");

  worksheet.columns = fileData.header;
  worksheet.addRows(fileData.body);

  const buffer = (await workbook.csv.writeBuffer()) as Uint8Array;
  const uint8Array = new Uint8Array(
    encoding.convert(buffer, {
      from: "UTF8",
      to: "SJIS",
    })
  );
  const blob = new Blob([uint8Array], {
    type: "application/octet-binary",
  });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = `${fileData.fileName}.csv`;
  a.click();
  a.remove();
};

export const checkUrlFormat = (value: string) => {
  const pattern = /^(https?)(:\/\/[\w/:%#$&?()~.=+-]+)/;
  return pattern.test(value);
};

export const fileStreamDownload = async (presighedUrl: string, fileName: string) => {
  await fetch(presighedUrl).then(async (response) => {
    const blob = response.body;
    const fileSize = Number(response.headers.get("content-length"));
    const fileStream = createWriteStream(fileName, { size: fileSize });
    await blob?.pipeTo(fileStream);
  });
};

export const useBlobFileStreamDownload = () => {
  const [isDownloading, setIsDownloading] = useState(false);
  const callBlobFileStreamDownload = async (blobUrl: string, fileName: string) => {
    try {
      setIsDownloading(true);
      await fetch(blobUrl).then(async (response) => {
        const blob = response.body;
        const fileStream = createWriteStream(fileName);
        await blob?.pipeTo(fileStream);
      });
    } finally {
      setIsDownloading(false);
    }
  };
  return { isDownloading, callBlobFileStreamDownload };
};

export const getLevelColorClassName = (level: number): string => {
  switch (level) {
    case 1:
      return "level1_color";
    case 2:
      return "level2_color";
    case 3:
      return "level3_color";
    case 4:
      return "level4_color";
    case 5:
      return "level5_color";
    default:
      return "grey";
  }
};

export const getLevelColor = (level: number): string => {
  switch (level) {
    case 1:
      return levelColorCodes.level1Color;
    case 2:
      return levelColorCodes.level2Color;
    case 3:
      return levelColorCodes.level3Color;
    case 4:
      return levelColorCodes.level4Color;
    case 5:
      return levelColorCodes.level5Color;
    default:
      return levelColorCodes.level1Color;
  }
};

export const contentTestStatus = (requiredTest?: boolean, existTest?: boolean): testStatus => {
  return !requiredTest ? "UNTEST" : existTest ? "TESTED" : "UNTEST";
};

export const encryptedString = (value: string): string => {
  return Buffer.from(value).toString("base64");
};

export const decryptedString = (value: string): string => {
  return Buffer.from(value, "base64").toString();
};

export const calcStudyRate = (
  notStudyTheme: number,
  studiedTheme: number,
  studyingTheme: number,
  totalTheme: number
) => {
  const notStudyRate = Math.round((notStudyTheme / totalTheme) * 100) || 0;
  const studyingRate = Math.round((studyingTheme / totalTheme) * 100) || 0;
  const studiedRate = Math.round((studiedTheme / totalTheme) * 100) || 0;
  const remainderRate = 100 - (notStudyRate + studyingRate + studiedRate);
  if (notStudyRate + studyingRate + studiedRate === 0) {
    return {
      notStudyRate: 0,
      studyingRate: 0,
      studiedRate: 0,
    };
  }
  if (remainderRate != 0) {
    if (notStudyRate > 0) {
      return {
        notStudyRate: notStudyRate + remainderRate,
        studyingRate,
        studiedRate,
      };
    } else if (studyingRate > 0) {
      return {
        notStudyRate,
        studyingRate: studyingRate + remainderRate,
        studiedRate,
      };
    } else {
      return {
        notStudyRate,
        studyingRate,
        studiedRate: studiedRate + remainderRate,
      };
    }
  }

  return {
    notStudyRate,
    studyingRate,
    studiedRate,
  };
};

export const getLocationParams = (params: string) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const vars: { [anyKey: string]: any } = {};
  let queryStr = decodeURIComponent(params);
  queryStr = queryStr.substr(queryStr.indexOf("?"));
  const param = queryStr.substring(1).split("&");
  for (let i = 0; i < param.length; i++) {
    const keySearch = param[i].search(/=/);
    let key = "";
    if (keySearch !== -1) key = param[i].slice(0, keySearch);
    const val = param[i].slice(param[i].indexOf("=", 0) + 1);
    if (key !== "") vars[key] = decodeURI(val);
  }
  return vars;
};

export const getAccountOriginalParams = (accountOriginalFlg: boolean, accountId: string | undefined) => {
  if (accountOriginalFlg && accountId !== undefined) {
    return { accountOriginalFlg: true, accountId: accountId };
  }
  return { accountOriginalFlg: false };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const sortedList = (array: any, key: string, desc: boolean, type?: "string" | "number") => {
  const nullToEmpty = (value: string) => {
    if (value == null || getLength(value) === 0) {
      return "";
    } else {
      return value;
    }
  };
  const orderType = (() => {
    if (desc) {
      return -1;
    } else {
      return 1;
    }
  })();
  if (!Array.isArray(key)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    array.sort((a: any, b: any) => {
      if (type === undefined || type === "string") {
        if (nullToEmpty(a[key]) > nullToEmpty(b[key])) {
          return 1 * orderType;
        } else if (nullToEmpty(a[key]) < nullToEmpty(b[key])) {
          return -1 * orderType;
        } else {
          return 0;
        }
      } else if (type === "number") {
        if (desc) {
          return b[key] - a[key];
        } else {
          return a[key] - b[key];
        }
      }
    });
  } else {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    array.sort((a: any, b: any) => {
      for (let i = 0; i < key.length; i++) {
        if (type === undefined || type === "string") {
          if (nullToEmpty(a[key]) > nullToEmpty(b[key])) {
            return 1 * orderType;
          } else if (nullToEmpty(a[key]) < nullToEmpty(b[key])) {
            return -1 * orderType;
          } else {
            return 0;
          }
        } else if (type === "number") {
          if (desc) {
            return b[key] - a[key];
          } else {
            return a[key] - b[key];
          }
        }
      }
      return null;
    });
  }
  return array;
};

export const reload = () => {
  window.location.reload();
};

export const hasDiff = <T,>(obj: T, obj2: T, skipKeys: string[] = []) => {
  if (typeof obj !== "object") {
    return obj != obj2;
  }
  if (obj === null || obj == undefined) {
    return obj != obj2;
  }
  for (const [key, value] of Object.entries(obj)) {
    if (skipKeys.includes(key)) {
      continue;
    }
    if (value != obj2[key as keyof typeof obj2]) {
      return true;
    }
  }
  return false;
};

export const getMonthNumberName = (monthEnName: monthEnName) => {
  switch (monthEnName) {
    case "january":
      return "1月";
    case "february":
      return "2月";
    case "march":
      return "3月";
    case "april":
      return "4月";
    case "may":
      return "5月";
    case "june":
      return "6月";
    case "july":
      return "7月";
    case "august":
      return "8月";
    case "september":
      return "9月";
    case "october":
      return "10月";
    case "november":
      return "11月";
    case "december":
      return "12月";
    default:
      break;
  }
};

export const getMonthNumber = (monthEnName: monthEnName) => {
  switch (monthEnName) {
    case "january":
      return 1;
    case "february":
      return 2;
    case "march":
      return 3;
    case "april":
      return 4;
    case "may":
      return 5;
    case "june":
      return 6;
    case "july":
      return 7;
    case "august":
      return 8;
    case "september":
      return 9;
    case "october":
      return 10;
    case "november":
      return 11;
    case "december":
      return 12;
    default:
      return 0;
  }
};

export const getMonthOrder = (monthEnName: monthEnName) => {
  switch (monthEnName) {
    case "january":
      return 10;
    case "february":
      return 11;
    case "march":
      return 12;
    case "april":
      return 1;
    case "may":
      return 2;
    case "june":
      return 3;
    case "july":
      return 4;
    case "august":
      return 5;
    case "september":
      return 6;
    case "october":
      return 7;
    case "november":
      return 8;
    case "december":
      return 9;
    default:
      return 0;
  }
};

export const getMonthNumberToOrder = (month: number) => {
  switch (month) {
    case 1:
      return 10;
    case 2:
      return 11;
    case 3:
      return 12;
    case 4:
      return 1;
    case 5:
      return 2;
    case 6:
      return 3;
    case 7:
      return 4;
    case 8:
      return 5;
    case 9:
      return 6;
    case 10:
      return 7;
    case 11:
      return 8;
    case 12:
      return 9;
    default:
      return 0;
  }
};

export const getMonthOrderToNumber = (month: number) => {
  switch (month) {
    case 1:
      return 4;
    case 2:
      return 5;
    case 3:
      return 6;
    case 4:
      return 7;
    case 5:
      return 8;
    case 6:
      return 9;
    case 7:
      return 10;
    case 8:
      return 11;
    case 9:
      return 12;
    case 10:
      return 1;
    case 11:
      return 2;
    case 12:
      return 3;
    default:
      return 0;
  }
};

export const getCurrentMonthToFiscalYear = (currentYear: number, month: number) => {
  if (month >= 4) {
    return currentYear;
  } else {
    return currentYear - 1;
  }
};

export const getUserFullName = (user: { firstName: string; lastName: string }) => {
  return user.lastName + " " + user.firstName;
};

export const getTestParamToDate = (param: string) => {
  if (getLength(param) !== 6) {
    return new Date();
  }
  const year = param.slice(0, 4);
  const month = param.slice(4, 6);
  return new Date(Number(year), Number(month) - 1);
};

export const usePrevious = <T,>(value: T) => {
  const ref = useRef(value);
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const isExistAnnounceUnreadCount = (unreadCount: number | string) => {
  switch (typeof unreadCount) {
    case "number":
      return unreadCount !== 0;
    case "string":
      return unreadCount !== "0";
    default:
      return false;
  }
};

export const getLegalBaseYearAndMonth = () => {
  const currentDate = new Date();
  return {
    baseYear: getCurrentMonthToFiscalYear(currentDate.getFullYear(), currentDate.getMonth() + 1),
    baseMonth: currentDate.getMonth() + 1,
  };
};

export const canInputReportNextAction = (nextAction: apiLoadNextAction | undefined, themeId: number | undefined) => {
  if (
    nextAction !== undefined &&
    nextAction.currentContentsType === "LEGAL" &&
    (nextAction.legal.nextAction === "REPORT_NOT_EXISTS_NEXT_CONTENTS" ||
      nextAction.legal.nextAction === "REPORT_EXISTS_NEXT_CONTENTS") &&
    themeId !== undefined &&
    nextAction.legal.imcompleteTheme.id === themeId
  ) {
    return true;
  }
  return false;
};

export const getUserInfoYears = () => {
  const startYear = 1900;
  const endYear = new Date().getFullYear();
  return Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i).map((year) => {
    return {
      key: year,
      text: year,
      value: year,
    };
  });
};

export const getUserInfoMonths = () => {
  const zeroPadding = (num: number) => {
    if (num < 10) {
      return "0" + num;
    }
    return num;
  };

  return Array.from({ length: 12 }, (_, i) => i + 1).map((month) => {
    return {
      key: month,
      text: zeroPadding(month),
      value: month,
    };
  });
};

export const getUserInfoDays = (year: number, month: number) => {
  const zeroPadding = (num: number) => {
    if (num < 10) {
      return "0" + num;
    }
    return num;
  };
  const lastDay = new Date(year, month, 0).getDate();
  return Array.from({ length: lastDay }, (_, i) => i + 1).map((day) => {
    return {
      key: day,
      text: zeroPadding(day),
      value: day,
    };
  });
};

export const getPersonnelChangeYear = () => {
  const startYear = new Date().getFullYear();
  const endYear = new Date().getFullYear() + 1;
  return Array.from({ length: endYear - startYear + 1 }, (_, i) => startYear + i).map((year) => {
    return {
      key: year,
      text: year,
      value: year,
    };
  });
};

export const getPersonnelChangeMonth = (year: number) => {
  const startMonth = year === new Date().getFullYear() ? new Date().getMonth() + 2 : 1;
  const endMonth = 12;
  return Array.from({ length: endMonth - startMonth + 1 }, (_, i) => startMonth + i).map((month) => {
    return {
      key: month,
      text: month,
      value: month,
    };
  });
};

export const getYearMonthToDate = (year: number | null, month: number | null) => {
  if (year === null || month === null) {
    return null;
  }
  return new Date(year as number, (month as number) - 1, 1);
};

export const getYearMonthDayToDate = (year: number | null, month: number | null, day: number | null) => {
  if (year === null || month === null || day === null) {
    return null;
  }
  return new Date(year as number, (month as number) - 1, day as number);
};

export const saveLocalStorageReportEditing = (
  accountId: string,
  userId: string,
  accountOriginalFlg: boolean,
  themeId: number,
  text: string
) => {
  const localReports = localStorage.getItem("themeReports");
  if (localReports) {
    const reports = JSON.parse(localReports);
    const anotherReports = reports.filter(
      (item: { accountId: string; userId: string; accountOriginalFlg: boolean; themeId: number; text: string }) => {
        return !(item.accountId === accountId && item.userId === userId && item.themeId === themeId);
      }
    );
    localStorage.setItem(
      "themeReports",
      JSON.stringify([
        ...anotherReports,
        {
          accountId: accountId,
          userId: userId,
          accountOriginalFlg: accountOriginalFlg,
          themeId: themeId,
          text: text,
        },
      ])
    );
  } else {
    const reports = [
      { accountId: accountId, userId: userId, accountOriginalFlg: accountOriginalFlg, themeId: themeId, text: text },
    ];
    localStorage.setItem("themeReports", JSON.stringify(reports));
  }
};

export const saveLocalStoragePlayerOptions = (accountId: string, userId: string, options: playerOptionsType) => {
  const localPlayerOptions = localStorage.getItem("playerOptions");
  if (localPlayerOptions) {
    const playerOptions = JSON.parse(localPlayerOptions);
    const anotherPlayerOptions = playerOptions.filter(
      (item: { accountId: string; userId: string; options: string }) => {
        return !(item.accountId === accountId && item.userId === userId);
      }
    );
    localStorage.setItem(
      "playerOptions",
      JSON.stringify([
        ...anotherPlayerOptions,
        {
          accountId: accountId,
          userId: userId,
          options: options,
        },
      ])
    );
  } else {
    const playerOptions = [{ accountId: accountId, userId: userId, options: options }];
    localStorage.setItem("playerOptions", JSON.stringify(playerOptions));
  }
};

export const saveLocalStorageExpirationDateBanner = (
  accountId: string,
  userId: string,
  open: boolean,
  usageEndDate: string | null
) => {
  const localExpirationDateBannerItems = localStorage.getItem("expirationDate");
  const expirationDateBannerItems = (() => {
    if (localExpirationDateBannerItems) {
      const item = JSON.parse(localExpirationDateBannerItems);
      if (Array.isArray(item)) {
        return item;
      }
      return [];
    }
    return [];
  })();
  if (usageEndDate !== null) {
    const expirationDate = new Date(usageEndDate);
    const expirationDateStr = expirationDate.toISOString();
    const expirationDateData = {
      accountId: accountId,
      userId: userId,
      open: open,
      expirationDate: expirationDateStr,
    };
    const anotherExpirationDateItems = expirationDateBannerItems.filter(
      (item: { accountId: string; userId: string }) => {
        return !(item.accountId === accountId && item.userId === userId);
      }
    );
    localStorage.setItem("expirationDate", JSON.stringify([...anotherExpirationDateItems, expirationDateData]));
  }
};

export const loadLocalStageReportEditing = (accountId: string, userId: string, themeId: number) => {
  const localReports = localStorage.getItem("themeReports");
  if (localReports) {
    const reports = JSON.parse(localReports);
    const report = reports.find(
      (item: { accountId: string; userId: string; accountOriginalFlg: boolean; themeId: number; text: string }) => {
        return item.accountId === accountId && item.userId === userId && item.themeId === themeId;
      }
    );
    if (report) {
      return report.text;
    }
    return "";
  }
  return "";
};

export const loadLocalStoragePlayerOptions = (accountId: string, userId: string) => {
  const localPlayerOptions = localStorage.getItem("playerOptions");
  if (localPlayerOptions) {
    const playerOptions = JSON.parse(localPlayerOptions);
    const userOptions = playerOptions.find((item: { accountId: string; userId: string; mode: string }) => {
      return item.accountId === accountId && item.userId === userId;
    });
    if (userOptions) {
      return userOptions.options;
    }
    return {
      playbackRate: 1,
      subtitles: {
        display: false,
        language: "",
      },
    };
  }
  return {
    playbackRate: 1,
    subtitles: {
      display: false,
      language: "",
    },
  };
};

export const loadLocalStageExpirationDateBanner = (accountId: string, userId: string) => {
  const localExpirationDateBannerItems = localStorage.getItem("expirationDate");
  if (localExpirationDateBannerItems) {
    const expirationDateBannerItems = (() => {
      if (localExpirationDateBannerItems) {
        const item = JSON.parse(localExpirationDateBannerItems);
        if (Array.isArray(item)) {
          return item;
        }
        return [];
      }
      return [];
    })();
    const expirationDateData = expirationDateBannerItems.find((item: { accountId: string; userId: string }) => {
      return item.accountId === accountId && item.userId === userId;
    });
    if (expirationDateData) {
      return expirationDateData;
    }
    return null;
  }
  return null;
};

export const getLoginUrl = (
  loginType: loginType,
  accountId: string,
  userId: string,
  mailAddress: string,
  phoneNumber: string
) => {
  switch (loginType) {
    case "PASSWORD":
      return `https://${APP_DOMAIN}/login?aid=${encodeURIComponent(accountId)}&uid=${encodeURIComponent(
        userId
      )}&fv=password`;
    case "EMAIL":
      return `https://${APP_DOMAIN}/login?ot=EMAIL&pe=${encodeURIComponent(mailAddress)}&fv=otp`;
    case "SMS":
      return `https://${APP_DOMAIN}/login?ot=SMS&pe=${encodeURIComponent(phoneNumber)}&fv=otp`;
    default:
      return `https://${APP_DOMAIN}/login?aid=${encodeURIComponent(accountId)}&uid=${encodeURIComponent(userId)}`;
  }
};

export const getInviteUrl = (inviteUlid: string) => {
  const appHost = APP_DOMAIN == "localhost" ? "http://localhost:3000" : `https://${APP_DOMAIN}`;
  return `${appHost}/invite?id=${encodeURIComponent(inviteUlid)}`;
};

export const getClientInfo = (): string => {
  const ua = window.navigator.userAgent.toLowerCase();
  // 端末、OS、OSバージョン、ブラウザ、ブラウザバージョンの取得
  const device =
    ua.indexOf("iphone") > -1
      ? "iPhone"
      : ua.indexOf("ipod") > -1
      ? "iPod"
      : ua.indexOf("ipad") > -1
      ? "iPad"
      : ua.indexOf("android") > -1 && ua.indexOf("mobile") > -1
      ? "Android"
      : "PC";

  const os =
    ua.indexOf("iphone") > -1 || ua.indexOf("ipod") > -1 || ua.indexOf("ipad") > -1
      ? "iOS"
      : ua.indexOf("android") > -1
      ? "Android"
      : ua.indexOf("windows") > -1
      ? "Windows"
      : ua.indexOf("macintosh") > -1
      ? "Mac"
      : "Other";

  const getOsVersion = (regex: RegExp) => {
    const osVersion = ua.match(regex);
    if (osVersion == null) {
      return "no set";
    }
    return osVersion[1].replace(/_/g, ".");
  };
  const osVersion =
    os === "iOS" ? getOsVersion(/os ([0-9_]+)/) : os === "Android" ? getOsVersion(/android ([0-9.]+)/) : "no set";

  const browser =
    ua.indexOf("edge") > -1
      ? "Edge"
      : ua.indexOf("chrome") > -1
      ? "Chrome"
      : ua.indexOf("safari") > -1
      ? "Safari"
      : ua.indexOf("firefox") > -1
      ? "Firefox"
      : ua.indexOf("ie") > -1
      ? "IE"
      : "Other";

  const getBrowserVersion = (regex: RegExp) => {
    const browserVersion = ua.match(regex);
    if (browserVersion == null) {
      return "no set";
    }
    return browserVersion[1];
  };

  const browserVersion =
    browser === "Chrome"
      ? getBrowserVersion(/chrome\/([0-9.]+)+/)
      : browser === "Safari"
      ? getBrowserVersion(/version\/([0-9.]+)+/)
      : browser === "Firefox"
      ? getBrowserVersion(/firefox\/([0-9.]+)+/)
      : browser === "Edge"
      ? getBrowserVersion(/edge\/([0-9.]+)+/)
      : browser === "IE"
      ? getBrowserVersion(/msie ([0-9.]+)/)
      : "no set";

  const clientInfo = {
    device: device,
    os: os,
    osVersion: osVersion,
    browser: browser,
    browserVersion: browserVersion,
    tabSessionId: getTabSessionId(),
  };

  return JSON.stringify(clientInfo);
};

// tabごとにセッションIDを発行する
export const getTabSessionId = () => {
  const tabSessionId = window.sessionStorage.getItem("tabSessionId");
  if (tabSessionId) {
    return tabSessionId;
  }
  window.sessionStorage.setItem("tabSessionId", uuidv4());
  return window.sessionStorage.getItem("tabSessionId") || "";
};

export type loginSessionType =
  | {
      type: "PASSWORD";
      accountId: string;
      userId: string;
      password: string;
      isSaveLoginInfo: boolean;
      currentPath: string;
      flowType: flowType;
      accountName?: string;
      customErrorMessage?: string;
    }
  | {
      type: otpType;
      phoneNumberOrEmail: string;
      otp: string;
      isSaveLoginInfo: boolean;
      currentPath: string;
      flowType: flowType;
      accountName?: string;
      customErrorMessage?: string;
    }
  | {
      type: "GROUP_STUDY";
      groupStudyUlid: string;
      accountId: string;
      userId: number; // user.id
      currentPath: string;
      flowType: "GROUP_STUDY_LOGIN";
      customErrorMessage?: string;
    };

export const setLoginSession = (info: loginSessionType) => {
  const loginSession =
    info.type === "PASSWORD"
      ? {
          type: info.type,
          accountId: info.accountId,
          userId: info.userId,
          password: info.password,
          isSaveLoginInfo: info.isSaveLoginInfo,
          currentPath: info.currentPath,
          flowType: info.flowType,
          accountName: info.accountName,
          customErrorMessage: info.customErrorMessage,
        }
      : info.type === "GROUP_STUDY"
      ? {
          type: info.type,
          accountId: info.accountId,
          groupStudyUlid: info.groupStudyUlid,
          userId: info.userId,
          currentPath: info.currentPath,
          flowType: info.flowType,
          customErrorMessage: info.customErrorMessage,
        }
      : {
          type: info.type,
          otp: info.otp,
          phoneNumberOrEmail: info.phoneNumberOrEmail,
          isSaveLoginInfo: info.isSaveLoginInfo,
          currentPath: info.currentPath,
          flowType: info.flowType,
          accountName: info.accountName,
          customErrorMessage: info.customErrorMessage,
        };

  sessionStorage.setItem(LOGIN_SESSION_INFO, encryptedString(JSON.stringify(loginSession)));
};

export const setLoginSessionErrorMessage = (customErrorMessage: string) => {
  const loginSession = getLoginSession();
  if (loginSession) {
    loginSession.customErrorMessage = customErrorMessage;
    setLoginSession(loginSession);
  }
};

export const getLoginSession = (): loginSessionType | null => {
  const loginSession = sessionStorage.getItem(LOGIN_SESSION_INFO);
  if (loginSession) {
    const decryptedLoginSession = decryptedString(loginSession);
    return JSON.parse(decryptedLoginSession);
  }
  return null;
};

export const isAuthRedirecting = () => {
  const loginSession = getLoginSession();
  return (
    loginSession != null &&
    loginSession.flowType in ["LOGIN", "SIGNUP", "SIGNUP_BY_ACCOUNT_INVITE", "GROUP_STUDY_LOGIN"]
  );
};

export const setIsAutoMigrate = (isAutoMigrate: boolean) => {
  isAutoMigrate ? sessionStorage.setItem(IS_AUTO_MIGRATE, "true") : sessionStorage.removeItem(IS_AUTO_MIGRATE);
};
export const isAutoMigrate = () => {
  return sessionStorage.getItem(IS_AUTO_MIGRATE) === "true";
};

export const deleteLoginSession = () => {
  sessionStorage.removeItem(LOGIN_SESSION_INFO);
};

export const setOtpReciver = (
  phoneNumberOrEmail: string,
  otpType: otpType,
  saveLoginInfo: boolean,
  flowType: flowType
) => {
  sessionStorage.setItem(
    OTP_SESSION_INFO,
    encryptedString(JSON.stringify({ phoneNumberOrEmail, otpType, saveLoginInfo, flowType }))
  );
};

export const getOtpReciver = (): {
  phoneNumberOrEmail: string;
  otpType: otpType;
  saveLoginInfo: boolean;
  flowType: flowType;
} | null => {
  const otpReciver = sessionStorage.getItem(OTP_SESSION_INFO);
  if (otpReciver) {
    try {
      return JSON.parse(decryptedString(otpReciver));
    } catch {
      deleteOtpReciver();
      return null;
    }
  }
  return null;
};

export const deleteOtpReciver = () => {
  localStorage.removeItem(OTP_SESSION_INFO);
};

export type userValidatiionResultType = { isValidate: boolean; errorMessage: string };
class ValidationError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "ValidationError";
  }
}

export const userValidation = (props: {
  accountId: string;
  userValue: inputUserValueType;
  userAdditionalInfo?: userAdditionalInfoInputType;
  automaticallyIssueRecommendedPasswords: boolean;
  automaticallyUserId: boolean;
  isNewOrChangeUserId: boolean;
}): userValidatiionResultType => {
  const {
    accountId,
    userValue,
    userAdditionalInfo,
    automaticallyIssueRecommendedPasswords,
    automaticallyUserId,
    isNewOrChangeUserId,
  } = props;
  try {
    checkValidation(userValue, automaticallyIssueRecommendedPasswords, automaticallyUserId);
    isNewOrChangeUserId && checkUserIdValidation(accountId, userValue, automaticallyUserId);
    checkMailAddressValidation(userValue);
    checkPhoneNumberValidation(userValue);
    checkUserAdditionalInfoValidation(userAdditionalInfo);

    return {
      isValidate: true,
      errorMessage: "",
    };
  } catch (e) {
    if (e instanceof ValidationError) {
      return {
        isValidate: false,
        errorMessage: e.message,
      };
    }
    throw e;
  }
};

const checkValidation = (
  userValue: inputUserValueType,
  automaticallyIssueRecommendedPasswords: boolean,
  automaticallyUserId: boolean
) => {
  const loginRequiredFields =
    userValue.loginType === "EMAIL" ? ["mailAddress"] : userValue.loginType === "SMS" ? ["phoneNumber"] : [];
  if (!automaticallyUserId) {
    loginRequiredFields.push("userId");
  }
  if (automaticallyIssueRecommendedPasswords || userValue.loginType != "PASSWORD") {
    if (checkBlankValidation(loginRequiredFields.concat(["lastName", "firstName", "type"]), userValue)) {
      return;
    }
  } else {
    if (checkBlankValidation(loginRequiredFields.concat(["password", "lastName", "firstName", "type"]), userValue)) {
      return;
    }
  }
  throw new ValidationError("入力されていない項目があります");
};

const checkUserIdValidation = (accountId: string, userValue: inputUserValueType, automaticallyUserId: boolean) => {
  if (automaticallyUserId) {
    return;
  }
  const isAutoGenerateUserIdFormat = new RegExp(`^${accountId}[1-9][0-9]*$`);
  if (getLength(userValue.userId) > 0 && isAutoGenerateUserIdFormat.test(userValue.userId.trim())) {
    throw new ValidationError(VALIDATIION_MESSAGE.INVALID_USERID);
  }
  const isUserIdLastPeriod = new RegExp(`[.]$`);
  if (isUserIdLastPeriod.test(userValue.userId)) {
    throw new ValidationError(VALIDATIION_MESSAGE.INVALID_USERID_LAST_PERIOD);
  }
  const isUserIdContinuousPeriod = new RegExp(`[.]{2,}`);
  if (isUserIdContinuousPeriod.test(userValue.userId)) {
    throw new ValidationError(VALIDATIION_MESSAGE.INVALID_USERID_CONTINUOUS_PERIOD);
  }
};

const checkMailAddressValidation = (userValue: inputUserValueType) => {
  if (getLength(userValue.mailAddress) > 0 && !mailAddressRegexp.test(userValue.mailAddress)) {
    throw new ValidationError(VALIDATIION_MESSAGE.INVALID_EMAIL);
  }
};

const checkPhoneNumberValidation = (userValue: inputUserValueType) => {
  if (getLength(userValue.phoneNumber) > 0 && !phoneNumberRegexp.test(userValue.phoneNumber)) {
    throw new ValidationError(VALIDATIION_MESSAGE.INVALID_PHONE_NUMBER);
  }
};

const checkUserAdditionalInfoValidation = (userAdditionalInfo?: userAdditionalInfoInputType) => {
  if (userAdditionalInfo == undefined) {
    // ユーザー属性情報がない場合はバリデーションをしない
    return;
  }
  const inputErrorItems = [];
  if (userAdditionalInfo.occupation === null) {
    inputErrorItems.push("occupation");
  }
  if (userAdditionalInfo.experienceYear === null) {
    inputErrorItems.push("experienceYear");
  }
  if (userAdditionalInfo.joinedCompanyDate.year !== null && userAdditionalInfo.joinedCompanyDate.month === null) {
    inputErrorItems.push("joinedCompanyDateMonth");
  }
  if (
    userAdditionalInfo.birthDate.year !== null &&
    (userAdditionalInfo.birthDate.month === null || userAdditionalInfo.birthDate.day === null)
  ) {
    if (userAdditionalInfo.birthDate.month === null) {
      inputErrorItems.push("birthDateMonth");
    }
    if (userAdditionalInfo.birthDate.day === null) {
      inputErrorItems.push("birthDateDay");
    }
  }
  if (inputErrorItems.length > 0) {
    throw new ValidationError("入力されていない項目があります");
  }
};

export const getKeyByValue = <T,>(object: Record<string, T>, value: T): string | undefined => {
  return Object.keys(object).find((key) => object[key] === value);
};

export const paramToBoolean = (value: string | null | undefined, initial: boolean) => {
  if (value !== null && value !== undefined) {
    if (value === "0") {
      return false;
    } else if (value === "1") {
      return true;
    }
    return initial;
  }
  return initial;
};

export const stringNumberToInteger = (value: string | null | undefined) => {
  if (value !== null && value !== undefined) {
    if (Number.isInteger(Number(value))) {
      return Number(value);
    }
  }
  return 0;
};

export const userIdInputFormat = (userId: string) => {
  return limitedString(getOnlyAlphaNumericSymbole(userId), 100);
};

export const accountIdInputFormat = (accountId: string) => {
  return limitedString(getOnlyAlphaNumericSymbole(accountId), 100);
};

export const passwordInputFormat = (password: string) => {
  return limitedString(getOnlyAlphaNumericSymbole(password), 100);
};

export const phoneNumberInputFormat = (phoneNumber: string) => {
  return limitedString(getOnlyNumeric(phoneNumber), 11);
};

export const emailInputFormat = (email: string) => {
  return limitedString(getOnlyAlphaNumericSymbole(email), 100);
};

export const getLanguageCodeToLabel = (languageCode: string) => {
  const label = subtitlelanguageOptions.find((item) => item.value === languageCode);
  if (label !== undefined) {
    return label.text;
  }
  return languageCode;
};

export const getLanguageCodeToSrcLang = (languageCode: string) => {
  const label = subtitlelanguageOptions.find((item) => item.value === languageCode);
  if (label !== undefined) {
    if (label.value === "ja_kana") {
      return "ja";
    }
    return label.value;
  }
  return languageCode;
};

export const getInquiryTitle = (
  inquiryType: inquiryType,
  inquiryDetailType: inquiryDetailType,
  contentsName: string,
  nameOnly?: boolean
) => {
  const formatedContentsName = getLength(contentsName) === 0 || !contentsName ? "コンテンツ未設定" : contentsName;
  switch (inquiryType) {
    case "QUESTION":
      if (nameOnly) {
        return `${formatedContentsName}`;
      } else {
        return `「${formatedContentsName}」へのご質問`;
      }
    case "PROBLEM":
      switch (inquiryDetailType) {
        case "DEFECTS_IN_CONTENT":
          return `「${formatedContentsName}」の不具合ご連絡`;
        case "DEFECTS_IN_OPERATION":
          return `Dスタ利用にあたっての不具合ご連絡`;
        case "OTHER":
          return `その他のご連絡`;
        case "NA":
          return "";
      }
      return "";
    case "OTHER":
      switch (inquiryDetailType) {
        case "REQUEST_CONTENT":
          return `受講コンテンツのご要望`;
        case "IMPROVEMENT_POINTS":
          return `Dスタ機能改善のご要望`;
        case "UNKNOWN_POINT":
          return `利用方法のご不明点`;
        case "OTHER":
          return `その他のご連絡`;
        case "NA":
          return "";
      }
      return "";
  }
};

export const getMypageTabList = (
  useDmSupportPlan: boolean,
  packageOnlyPlanFlg: boolean,
  useAccountOriginal: boolean,
  usePackage: boolean,
  userType: userType,
  mode: deviceType
) => {
  const mypageTabs: { tabName: mypageTabName; tabLabel: string; index: number }[] = [];
  const isAdmin = userType === "DM_ADMIN" || userType === "ACCOUNT_ADMIN";
  let index = 0;
  if (packageOnlyPlanFlg) {
    mypageTabs.push(
      { tabName: "PACKAGE", tabLabel: "パッケージ", index: index },
      { tabName: "BOOKMARK", tabLabel: "ブックマーク", index: ++index },
      { tabName: "HISTORY", tabLabel: "学習履歴", index: ++index }
    );
    return mypageTabs;
  }

  if (useDmSupportPlan) {
    mypageTabs.push({ tabName: "LEGAL", tabLabel: "法定研修", index: index });
    if (isAdmin && mode === "PC") {
      mypageTabs.push({ tabName: "LEGAL_GROUP_STUDY", tabLabel: "法定研修（集団受講）", index: ++index });
    }
  } else {
    if (isAdmin) {
      if (mode === "PC") {
        mypageTabs.push(
          { tabName: "LEGAL", tabLabel: "法定研修", index: index },
          { tabName: "LEGAL_GROUP_STUDY", tabLabel: "法定研修（集団受講）", index: ++index },
          { tabName: "CAREER", tabLabel: "キャリアパス", index: ++index }
        );
      } else {
        mypageTabs.push(
          { tabName: "LEGAL", tabLabel: "法定研修", index: index },
          { tabName: "CAREER", tabLabel: "キャリアパス", index: ++index }
        );
      }
    } else {
      mypageTabs.push(
        { tabName: "LEGAL", tabLabel: "法定研修", index: index },
        { tabName: "CAREER", tabLabel: "キャリアパス", index: ++index }
      );
    }
  }

  if (usePackage) {
    mypageTabs.push({ tabName: "PACKAGE", tabLabel: "パッケージ", index: ++index });
  }

  mypageTabs.push({ tabName: "FREE_CONTENTS", tabLabel: "フリーコンテンツ", index: ++index });

  if (useAccountOriginal) {
    mypageTabs.push({ tabName: "ACCOUNT_ORIGINAL", tabLabel: "施設オリジナル", index: ++index });
  }

  mypageTabs.push(
    { tabName: "TUTORIAL", tabLabel: "チュートリアル", index: ++index },
    { tabName: "BOOKMARK", tabLabel: "ブックマーク", index: ++index },
    { tabName: "HISTORY", tabLabel: "学習履歴", index: ++index }
  );
  return mypageTabs;
};

export const isWithin14Days = (date: Date) => {
  const now = new Date();
  // 送られてくる値はUTC時間になっているが実際はJSTとして扱うため-9時間してから日本時間に変換して扱う
  const target = new Date(date.getTime() - 9 * 60 * 60 * 1000);
  const diff = target.getTime() - now.getTime();
  return diff <= 14 * 24 * 60 * 60 * 1000;
};

export const getAccountPlan = (
  useDmSupportPlan: boolean,
  usePackageOnlyPlan: boolean
): { code: accountPlanCode; label: string } => {
  if (useDmSupportPlan) {
    return { code: "LIGHT", label: "ライト" };
  }
  if (usePackageOnlyPlan) {
    return { code: "PACKAGE", label: "パッケージのみ" };
  }
  return { code: "FULL", label: "フル" };
};

export const getAccountPlanToFlg = (accountPlan: accountPlanType) => {
  switch (accountPlan) {
    case "FULL":
      return { useDmSupportPlan: false, usePackageOnlyPlan: false };
    case "LIGHT":
      return { useDmSupportPlan: true, usePackageOnlyPlan: false };
    case "PACKAGE":
      return { useDmSupportPlan: false, usePackageOnlyPlan: true };
  }
};

export const existCanReportUser = (groupStudiedUsers: apiLoadGroupStudiedUser[]) => {
  if (groupStudiedUsers.length > 0) {
    const canReportUser = groupStudiedUsers.find((item) => numberToBoolean(item.canReport) && !item.reported);
    if (canReportUser) {
      return true;
    }
  }
  return false;
};
