import { UserInfo } from "../contexts/UserContext";
import { getUserTypeText } from "../util/common.util";
import { FULLSTAR_ID, experienceYearText } from "../static";

export const loadFullstarScript = (userContext?: UserInfo) => {
  if (!FULLSTAR_ID || FULLSTAR_ID === "") {
    // FullstarのIDが設定されていない場合は何もしない
    return;
  }

  const scriptId = "fullstar-script";
  const existingScript = document.getElementById(scriptId);
  if (existingScript) {
    // ページ遷移時すでに存在していたら一度削除してから読み込む
    existingScript.remove();
  }
  const script = document.createElement("script");
  script.id = scriptId;

  const sendFiles = [];
  if (userContext?.accountName) {
    sendFiles.push(`company_name:"${userContext.accountName}"`);
  }
  if (userContext?.lastName && userContext?.firstName) {
    sendFiles.push(`user_name:"${userContext.lastName} ${userContext.firstName}"`);
  }
  // email addressだけつねに項目を送ってほしいという要望だったため、空欄の場合はダミー文字列をおくる
  // https://www.notion.so/dazzling-fireplace-74e/fullstar-33d64973096a4823b4ac6f359ffc286f?pvs=4
  // https://www.notion.so/dazzling-fireplace-74e/fullstar-0af12c48a0584ebdbfab0efbd4560dff#daf03dee5a8840fd99595a4c56dbc5de
  sendFiles.push(`user_email:"${userContext?.mailAddress || "dummy@doctormate.co.jp"}"`);

  if (userContext) {
    sendFiles.push(`plan_name:"${userContext.useDmSupportPlan ? 1 : 0}"`);
  }
  const tags = [];
  if (userContext?.occupation) {
    tags.push(userContext.occupation);
  }
  if (userContext?.type) {
    tags.push(getUserTypeText(userContext.type));
  }
  if (userContext?.experienceYear) {
    tags.push(experienceYearText[userContext.experienceYear]);
  }
  if (tags.length > 0) {
    sendFiles.push(`tags:"${tags.join(",")}"`);
  }
  const sendJsonText = sendFiles.join(", ");
  const uuidByUserId = userContext?.userId || "no set";

  script.innerHTML = `
  fs.clear();fs.show("${FULLSTAR_ID}", "${uuidByUserId}", { ${sendJsonText} });fs.tracking();fs.survey();fs.guide();fs.checklist();fs.tooltip();
  `;

  document.body.appendChild(script);
};

export const clearFullstarScript = () => {
  const scriptId = "fullstar-script";
  const existingScript = document.getElementById(scriptId);
  if (existingScript) {
    existingScript.remove();
  }
};

export const appendHeaderFullstarScript = () => {
  if (!FULLSTAR_ID || FULLSTAR_ID === "") {
    // FullstarのIDが設定されていない場合は何もしない
    return;
  }

  const scriptId = "fullstar-header-script";
  const existingScript = document.getElementById(scriptId);
  if (existingScript) {
    // ページ遷移時すでに存在していたら何もしない
    return;
  }
  // 下記タグを作成してheaderに追加する
  // <script type="application/javascript" src="https://view.fullstar10.com/js/review.js"></script>
  const script = document.createElement("script");
  script.id = scriptId;
  script.type = "application/javascript";
  script.src = `https://view.fullstar10.com/js/review.js`;

  document.head.appendChild(script);
  return;
};
