import React, { createContext, useReducer, useContext } from "react";
import { childrenProps } from "../types";

import {
  setTokenCookie,
  clearAuthCookie,
  clearCurrentAccountStorage,
  getCookieToken,
  setIsAutoMigrate,
} from "./../util/common.util";

type loginInfo = {
  isLoggedIn: boolean;
  isLogoutBannerShown: boolean;
  isInitialized: boolean;
};

const initialState = {
  isLoggedIn: getCookieToken() !== undefined,
  isLogoutBannerShown: false,
  isInitialized: true,
} as loginInfo;

const AuthContext = createContext(
  {} as {
    contextData: loginInfo;
    dispatch: React.Dispatch<action>;
  }
);

type actionType = "LOGIN" | "LOGOUT" | "HIDE_LOGOUT_BANNER" | "HIDE_LOGOUT_BANNER" | "INITIALIZE";
type action = {
  type: actionType;
  showLogoutBanner?: boolean;
};

export const authInitialize = (): action => {
  return { type: "INITIALIZE" };
};

export const authLogin = (tokens: { accessToken: string; expiresIn: number; isGroupStudy?: boolean }): action => {
  const { accessToken, expiresIn, isGroupStudy } = tokens;
  setTokenCookie(accessToken, expiresIn, isGroupStudy);
  setIsAutoMigrate(false);
  return { type: "LOGIN" };
};

export const authLogout = (showLogoutBanner: boolean): action => {
  clearAuthCookie();
  clearCurrentAccountStorage();
  return { type: "LOGOUT", showLogoutBanner };
};

export const hideLogoutBanner = (): action => {
  return { type: "HIDE_LOGOUT_BANNER" };
};

const authReducer = (state: loginInfo, action: action): loginInfo => {
  switch (action.type) {
    case "LOGIN":
      return {
        isInitialized: false,
        isLoggedIn: true,
        isLogoutBannerShown: false,
      };
    case "LOGOUT":
      return {
        isInitialized: false,
        isLoggedIn: false,
        isLogoutBannerShown: action.showLogoutBanner || false,
      };
    case "HIDE_LOGOUT_BANNER":
      return { isInitialized: false, isLoggedIn: state.isLoggedIn, isLogoutBannerShown: false };
    case "INITIALIZE":
      return {
        ...state,
        isInitialized: true,
      };

    default:
      return state;
  }
};

export const AuthProvider = (props: childrenProps) => {
  const [contextData, dispatch] = useReducer(authReducer, initialState);
  const data = { contextData, dispatch };
  return <AuthContext.Provider value={data} {...props} />;
};

export const useAuthContext = () => {
  return useContext(AuthContext);
};
