import React, { createContext, useReducer, useContext } from "react";
import { childrenProps } from "../types";

type ToastBannerState = {
  isDisplayed: boolean;
  message: string | null;
  status: "success" | null;
};

const initialState = {
  isDisplayed: false,
  message: "",
  status: null,
} as ToastBannerState;

const ToastBannerContext = createContext(
  {} as {
    contextData: ToastBannerState;
    dispatch: ToastBannerDispatch;
  }
);

export type ToastBannerDispatch = React.Dispatch<action>;

type actionType = "HIDE" | "IS_SUCCESS";
type action = {
  type: actionType;
  message: string | null;
};

export const isSuccess = (message: string): action => {
  return { type: "IS_SUCCESS", message };
};

export const hide = (): action => {
  return { type: "HIDE", message: null };
};

const toastBannerReducer = (state: ToastBannerState, action: action): ToastBannerState => {
  switch (action.type) {
    case "IS_SUCCESS":
      return { isDisplayed: true, message: action.message, status: "success" };
    case "HIDE":
      return { isDisplayed: false, message: null, status: null };
    default:
      return state;
  }
};

export const ToastBannerProvider = (props: childrenProps) => {
  const [contextData, dispatch] = useReducer(toastBannerReducer, initialState);
  const data = { contextData, dispatch };
  return <ToastBannerContext.Provider value={data} {...props} />;
};

export const useToastBannerContext = () => {
  return useContext(ToastBannerContext);
};
