import React, { createContext, useReducer, useContext } from "react";
import { childrenProps } from "../types";

type FloatBannerState = {
  isDisplayed: boolean;
  title: string | null;
  message: string | null;
  status: "info" | "error" | "success" | null;
};

const initialState = {
  isDisplayed: false,
  title: "",
  message: "",
  status: null,
} as FloatBannerState;

const FloatBannerContext = createContext(
  {} as {
    contextData: FloatBannerState;
    dispatch: FloatBannerDispatch;
  }
);

export type FloatBannerDispatch = React.Dispatch<action>;

type actionType = "HIDE" | "IS_INFO" | "IS_ERROR" | "IS_SUCCESS";
type action = {
  type: actionType;
  title: string | null;
  message: string | null;
};

export const isError = (title: string, message: string): action => {
  return { type: "IS_ERROR", title, message };
};

export const isSuccess = (title: string, message: string): action => {
  return { type: "IS_SUCCESS", title, message };
};

export const isInfo = (title: string, message: string): action => {
  return { type: "IS_INFO", title, message };
};

export const hide = (): action => {
  return { type: "HIDE", title: null, message: null };
};

const floatBannerReducer = (state: FloatBannerState, action: action): FloatBannerState => {
  switch (action.type) {
    case "IS_ERROR":
      return { isDisplayed: true, title: action.title, message: action.message, status: "error" };
    case "IS_SUCCESS":
      return { isDisplayed: true, title: action.title, message: action.message, status: "success" };
    case "IS_INFO":
      return { isDisplayed: true, title: action.title, message: action.message, status: "info" };
    case "HIDE":
      return { isDisplayed: false, title: null, message: null, status: null };
    default:
      return state;
  }
};

export const FloatBannerProvider = (props: childrenProps) => {
  const [contextData, dispatch] = useReducer(floatBannerReducer, initialState);
  const data = { contextData, dispatch };
  return <FloatBannerContext.Provider value={data} {...props} />;
};

export const useFloatBannerContext = () => {
  return useContext(FloatBannerContext);
};
