// APIで422のエラーとなった場合のError
// e.messageにバリデーションメッセージが入る
export class BadRequestException extends Error {
  constructor(message: string, checkResult?: object[]) {
    super(message);
    this.name = "BadRequestException";
    this.statusCode = 422;
    this.checkResult = checkResult;
  }

  private statusCode: number;
  private checkResult: object[] | undefined;

  public getStatusCode(): number {
    return this.statusCode;
  }

  public getCheckResult(): object[] | undefined {
    return this.checkResult;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const isBadRequestException = (error: any): error is BadRequestException => {
  return error?.name === "BadRequestException";
};
