import { postAuthApi, postAuthCustomHeaderApi } from "./api-base";

import {
  apiUserInfo,
  apiLoadCorporationPermitAccounts,
  apiUserInfoError,
  apiLoginSuccess,
  apiInviteInfoResponse,
  UserSignupRequest,
  AccountSignupRequest,
  SignUpResponse,
  apiRefreshToken,
  flowType,
  apiLoginSuccessError,
  apiGroupStudyLoginResponse,
  apiGroupStudyLoginRequest,
  apiLoadGroupStudyRequest,
  apiGroupStudyResponse,
} from "./../../types";
import { getDeployDateCookie, getTabSessionId } from "../../util/common.util";

export const loadUserInfoApi = async (): Promise<apiUserInfo | apiUserInfoError> => {
  const requestBody = {
    eventType: "USER_INFO",
  };
  return await postAuthApi(requestBody, false);
};

export const loginSuccessApi = async (
  accessToken: string,
  flowType: flowType
): Promise<apiLoginSuccess | apiLoginSuccessError> => {
  const requestBody = {
    eventType: "LOGIN_SUCCESS",
    flowType: flowType,
  };
  return await postAuthCustomHeaderApi(requestBody, {
    headers: { Authorization: "Bearer " + accessToken },
  });
};

export const loadCorporationPermitAccountsApi = async (): Promise<apiLoadCorporationPermitAccounts> => {
  const requestBody = {
    eventType: "LOAD_CORPORATION_PERMIT_ACCOUNTS",
  };
  return await postAuthApi(requestBody, false);
};

export const loadInviteInfoApi = (props: { ulid: string }): Promise<apiInviteInfoResponse> => {
  const requestBody = {
    eventType: "LOAD_INVITE_INFO",
    inviteUlid: props.ulid,
  };
  return postAuthApi(requestBody, true);
};

export const userSignUpApi = (userSignup: UserSignupRequest): Promise<SignUpResponse> => {
  const requestBody = {
    eventType: "USER_SIGNUP",
    ...userSignup,
  };
  return postAuthApi(requestBody, true);
};

export const accountSignUpApi = (accountSignUp: AccountSignupRequest): Promise<SignUpResponse> => {
  const requestBody = {
    eventType: "ACCOUNT_SIGNUP",
    ...accountSignUp,
  };
  return postAuthApi(requestBody, true);
};

export const refrershTokenApi = async (refreshToken: string): Promise<apiRefreshToken> => {
  const requestBody = {
    eventType: "REFRESH_TOKEN",
    refreshToken,
  };
  return postAuthApi(requestBody, true);
};

export const checkLoginKey = (props: { loginType: "SMS" | "EMAIL"; loginKey: string }): Promise<SignUpResponse> => {
  const { loginType, loginKey } = props;
  const requestBody = {
    eventType: "CHECK_LOGIN_KEY",
    loginType,
    loginKey,
  };
  return postAuthApi(requestBody, true);
};

export const registerFrontAuthLog = (
  userId: string | "NOT_LOGIN",
  accountId: string | "NOT_LOGIN",
  message: string,
  options?: unknown
): Promise<SignUpResponse> => {
  const requestBody = {
    eventType: "REGISTER_FRONT_AUTH_LOG",
    userId,
    accountId,
    tabSessionId: getTabSessionId(),
    message,
    options,
  };
  return postAuthApi(requestBody, true);
};

export const checkFrontVersionApi = (): Promise<SignUpResponse> => {
  const requestBody = {
    eventType: "CHECK_FRONT_VERSION",
    // デバッグ時意図的にバージョン更新動作をおこすためにcookieから取得
    // cookieへのセットは初期ロード時の瞬間に環境変数から取得するものとする
    frontVersion: getDeployDateCookie(),
  };
  return postAuthApi(requestBody, true);
};

export const groupStudyLoginApi = (groupStudyLogin: apiGroupStudyLoginRequest): Promise<apiGroupStudyLoginResponse> => {
  const requestBody = {
    eventType: "GROUP_STUDY_LOGIN",
    ...groupStudyLogin,
  };
  return postAuthApi(requestBody, true);
};

export const loadGroupStudyApi = (props: apiLoadGroupStudyRequest): Promise<apiGroupStudyResponse> => {
  const requestBody = {
    eventType: "LOAD_GROUP_STUDY",
    ...props,
  };
  return postAuthApi(requestBody, true);
};
