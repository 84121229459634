import React, { createContext, useReducer, useContext, useMemo } from "react";
import { childrenProps, apiUnconfirmedReport, announceBannerType } from "../types";
import { getCommonAndOriginalId, undefinedToNumber } from "../util/common.util";

type BadgeInfo = {
  unsolvedCount: string | number; ///ユーザーが質問した、未解決かつ返答があるものを取得
  unconfirmedReportCount: string | number;
  unconfirmedReportDetail: Map<string, number | string>; // categoryId(commonAndOriginalId), unconfirmedReportCount
  unreadCount: string | number;
  banners: announceBannerType[];
};

const initialState = {
  unsolvedCount: 0,
  unconfirmedReportCount: 0,
  unconfirmedReportDetail: new Map<string, number>(),
  unreadCount: 0,
  banners: [],
} as BadgeInfo;

const BadgeContext = createContext(
  {} as {
    badgeContext: BadgeInfo;
  }
);
const BadgeDispatchContext = createContext(
  {} as {
    dispatch: BadgeDispatch;
  }
);

export type BadgeDispatch = React.Dispatch<action>;

type actionType = "SET_UNSOLVED_COUNT" | "SET_UNCONFIRMED_COUNT" | "SET_ANNOUNCE" | "SET_ALL_BADGE_COUNT";
//  "IS_INFO" | "IS_ERROR" | "IS_SUCCESS";
type action = {
  type: actionType;
  unsolvedCount?: number;
  unconfirmedReportCount?: number;
  unconfirmedReportDetail?: Map<string, number | string>;
  unreadCount?: number;
  banners?: announceBannerType[];
};

export const setUnsolvedCount = (unsolvedCount: number): action => {
  return {
    type: "SET_UNSOLVED_COUNT",
    unsolvedCount: unsolvedCount,
  };
};

export const setUnconfirmedReportCount = (unconfirmedReports: apiUnconfirmedReport[] | null | undefined): action => {
  const result = convertUnconfirmedBadges(unconfirmedReports);

  return {
    ...result,
    type: "SET_UNCONFIRMED_COUNT",
  };
};

export const setAnnounce = (unreadCount: number, banners: announceBannerType[]): action => {
  return {
    type: "SET_ANNOUNCE",
    unreadCount: unreadCount,
    banners: banners,
  };
};

export const setAllBadgeCount = (
  unsolvedCount: number,
  unconfirmedReports: apiUnconfirmedReport[] | null | undefined,
  unreadCount: number,
  banners: announceBannerType[]
): action => {
  const unconfirmedBadges = convertUnconfirmedBadges(unconfirmedReports);
  return {
    ...unconfirmedBadges,
    type: "SET_ALL_BADGE_COUNT",
    unsolvedCount: unsolvedCount,
    unreadCount: unreadCount,
    banners: banners,
  };
};

export const convertUnconfirmedBadges = (unconfirmedReports: apiUnconfirmedReport[] | null | undefined) => {
  if (!unconfirmedReports || unconfirmedReports.length === 0) {
    return {
      unconfirmedReportCount: 0,
      unconfirmedReportDetail: new Map<string, number | string>(),
    };
  }

  return unconfirmedReports.reduce(
    (result, currentItem) => {
      result.unconfirmedReportCount = result.unconfirmedReportCount + currentItem.unconfirmedCount;
      result.unconfirmedReportDetail.set(
        getCommonAndOriginalId(currentItem.accountOriginalFlg, "category", currentItem.categoryId),
        currentItem.unconfirmedCount
      );
      return result;
    },
    {
      // 返却されるデータ形式
      unconfirmedReportCount: 0,
      unconfirmedReportDetail: new Map<string, number | string>(),
    }
  );
};

const OVER_MAX_COUNT_STR = "99+";
export const convertTotalBadgeCount = (badgeInfo: BadgeInfo): number | string => {
  // 未確認レポートのバッジは出さない場合は下記1行
  // return badgeInfo.unsolvedCount === OVER_MAX_COUNT_STR ? OVER_MAX_COUNT_STR : badgeInfo.unconfirmedReportCount;

  if (typeof badgeInfo.unsolvedCount === "string" || typeof badgeInfo.unconfirmedReportCount === "string") {
    return OVER_MAX_COUNT_STR;
  }
  const totalCount = badgeInfo.unsolvedCount + badgeInfo.unconfirmedReportCount;
  return totalCount > 99 ? OVER_MAX_COUNT_STR : totalCount;
};

const convertBadgeCount = (count: number): number | string => {
  return count >= 100 ? OVER_MAX_COUNT_STR : count;
};

const badgeReducer = (state: BadgeInfo, action: action): BadgeInfo => {
  switch (action.type) {
    case "SET_UNSOLVED_COUNT":
      if (action.unsolvedCount == undefined) return state;
      return {
        ...state,
        unsolvedCount: convertBadgeCount(action.unsolvedCount),
      };
    case "SET_UNCONFIRMED_COUNT":
      if (action.unconfirmedReportCount == undefined || action.unconfirmedReportDetail == undefined) return state;
      return {
        ...state,
        unconfirmedReportCount: convertBadgeCount(action.unconfirmedReportCount),
        unconfirmedReportDetail: action.unconfirmedReportDetail,
      };
    case "SET_ANNOUNCE":
      return {
        ...state,
        unreadCount: convertBadgeCount(undefinedToNumber(action.unreadCount, 0)),
        banners: action.banners || [],
      };
    case "SET_ALL_BADGE_COUNT":
      if (
        action.unconfirmedReportCount == undefined ||
        action.unconfirmedReportDetail == undefined ||
        action.unsolvedCount == undefined
      )
        return state;
      return {
        ...state,
        unconfirmedReportCount: convertBadgeCount(action.unconfirmedReportCount),
        unconfirmedReportDetail: action.unconfirmedReportDetail,
        unsolvedCount: convertBadgeCount(action.unsolvedCount),
        unreadCount: convertBadgeCount(undefinedToNumber(action.unreadCount, 0)),
        banners: action.banners || [],
      };
    default:
      return state;
  }
};

export const BadgeProvider = (props: childrenProps) => {
  const [contextData, dispatch] = useReducer(badgeReducer, initialState);
  const badgeContext = useMemo(
    () => ({
      badgeContext: contextData,
    }),
    [contextData]
  );
  // dispatchは変更なくてもcontextの更新で変更ありとみなされ、再描画してしまう
  // dispatch部分しか使わない部分は不要な再描画が起きないよう、useMemoを利用して再描画をロックする
  const badgeDispatch = useMemo(
    () => ({
      dispatch,
    }),
    []
  );

  return (
    <BadgeContext.Provider value={badgeContext}>
      <BadgeDispatchContext.Provider value={badgeDispatch} {...props} />
    </BadgeContext.Provider>
  );
};

export const useBadgeContext = () => {
  return useContext(BadgeContext);
};

export const useBadgeDispatch = () => {
  return useContext(BadgeDispatchContext);
};
