import axios, { AxiosInstance, AxiosRequestConfig, InternalAxiosRequestConfig } from "axios";

import { API_URL, AUTH_ERROR } from "../../static";
import {
  getCookieToken,
  setTokenCookie,
  clearAuthCookie,
  getDeployDateCookie,
  getCurrentAccountStorage,
  getClientInfo,
} from "../../util/common.util";
import { checkSession } from "../../util/auth0.util";
import { Auth0DecodedHash } from "auth0-js";
import { BadRequestException } from "../../util/exception/BadRequestException";

const SKIP_DEPLOY_DATE_FOR_LOCAL = "";

const dmApi = axios.create();
dmApi.defaults.baseURL = API_URL;
dmApi.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const token = getCookieToken();
  if (!token) {
    // トークン再発行も失敗した場合はbannerを出さないエラーに変更
    throw new Error("user timeout");
  }

  config.headers["Authorization"] = token;

  const deployDate = getDeployDateCookie() || SKIP_DEPLOY_DATE_FOR_LOCAL;
  config.headers && (config.headers["x-deploy-date"] = deployDate);

  const currentAccount = getCurrentAccountStorage();
  if (currentAccount) {
    config.headers && (config.headers["x-account-id"] = currentAccount.id);
  }
  const clientInfo = getClientInfo();
  if (clientInfo) {
    config.headers && (config.headers["x-client-info"] = clientInfo);
  }
  return config;
});

// ログインなどに使うので、header情報を載せない
const dmNoVerifyApi = axios.create();
dmNoVerifyApi.defaults.baseURL = API_URL;

dmNoVerifyApi.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const clientInfo = getClientInfo();
  if (clientInfo) {
    config.headers && (config.headers["x-client-info"] = clientInfo);
  }
  const deployDate = getDeployDateCookie() || SKIP_DEPLOY_DATE_FOR_LOCAL;
  config.headers && (config.headers["x-deploy-date"] = deployDate);

  return config;
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const makeAxionsInsance = (axiosInstance: AxiosInstance, refreshTokenFunc?: () => Promise<any>) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const isTokenExpired = (e: any) => {
    const { response } = e;
    return response && response.status === 401 && response.data.errorMessage === AUTH_ERROR.TOKEN_EXPIRED;
  };

  return {
    axiosInstance: axiosInstance,
    endpoint: "",
    400: "入力内容が不正です。大変申し訳ございませんが、ご確認の上、もう一度お試しください。", //Invalid parameter.
    401: "ログイン情報の有効期限が切れました。再度ログインし直してください", // Invarid Token
    402: "エラーが発生しました。大変申し訳ございませんが、しばらくたってからもう一度お試しください。", //The parameters were valid but the request failed
    403: "エラーが発生しました。大変申し訳ございませんが、再度ログインの上もう一度お試しください。", //No valid access token provided
    404: "エラーが発生しました。大変申し訳ございませんが、しばらくたってからもう一度お試しください。", //The requested resource doesn't exist
    genericError: "エラーが発生しました。大変申し訳ございませんが、しばらくたってからもう一度お試しください。", //Something went wrong. Please try again.
    //   get: function () {
    //     return this.axiosInstance
    //       .get(this.endpoint)
    //       .then((res) => res.data)
    //       .catch((e) => this.errorHandling(e));
    //   },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    post: function (body: any, config: AxiosRequestConfig = {}) {
      return this.axiosInstance
        .post(this.endpoint, body, config)
        .then((res) => res.data)
        .catch((e) => {
          if (isTokenExpired(e) && !!refreshTokenFunc) {
            // トークン再発行
            return refreshTokenFunc()
              .then(() => {
                return this.axiosInstance
                  .post(this.endpoint, body, config)
                  .then((res) => res.data)
                  .catch((e) => {
                    this.checkAuthError(e);
                    this.errorHandling(e);
                  });
              })
              .catch(() => {
                this.checkAuthError(e);
                this.errorHandling(e);
              });
          }
          this.checkAuthError(e);
          return this.errorHandling(e);
        });
    },

    //   patch: function (body: any) {
    //     return this.axiosInstance
    //       .patch(this.endpoint, body)
    //       .then((res) => res.data)
    //       .catch((e) => this.errorHandling(e));
    //   },
    //   delete: function (body: any) {
    //     return this.axiosInstance
    //       .delete(this.endpoint)
    //       .then((res) => res.data)
    //       .catch((e) => this.errorHandling(e));
    //   },
    //   put: function (data: any, config) {
    //     return this.axiosInstance
    //       .put(this.endpoint, data, config)
    //       .then((res) => res.data)
    //       .catch((e) => this.errorHandling(e));
    //   },

    checkAuthError: function (e: {
      response: { status?: number; data: { errorMessage?: string; deployDate?: string } };
    }) {
      const { response } = e;
      if (!response || !response.status || response.status !== 401) return;
      switch (response.data.errorMessage) {
        case AUTH_ERROR.USER_INVALD:
          throw new Error(AUTH_ERROR.USER_INVALD);
        case AUTH_ERROR.ACCOUNT_INVALID:
          throw new Error(AUTH_ERROR.ACCOUNT_INVALID);
        case AUTH_ERROR.ACCOUNT_OVER_END_DATE:
          throw new Error(AUTH_ERROR.ACCOUNT_OVER_END_DATE);
        case AUTH_ERROR.ACCOUNT_NO_PERMISSION:
          throw new Error(AUTH_ERROR.ACCOUNT_NO_PERMISSION);
        case AUTH_ERROR.LOGIN_INVALID:
          throw new Error(AUTH_ERROR.LOGIN_INVALID);
        case AUTH_ERROR.USER_TIMEOUT:
          throw new Error(AUTH_ERROR.USER_TIMEOUT);
        case AUTH_ERROR.FRONT_INVALID:
          // frontの最新deployDateは画面リロード時の初期読み込みでfrontリソースの環境変数から取得に変更
          throw new Error(AUTH_ERROR.FRONT_INVALID);
        default:
          return;
      }
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    errorHandling: function (e: any) {
      const { response } = e;
      if (response !== undefined) {
        switch (response.status) {
          case 400:
            throw new Error(this[400]);
          case 402:
            throw new Error(this[402]);
          case 403:
            throw new Error(this[403]);
          case 404:
            throw new Error(this[404]);
          case 409:
            throw new Error(e.response.data.message);
          case 422: {
            // 422の場合errorMessageにバリデーションメッセージが返ってくる
            // message
            const errorMessage = "errorMessage" in e.response.data ? e.response.data.errorMessage : undefined;
            const checkResult = "checkResult" in e.response.data ? e.response.data.checkResult : undefined;
            throw new BadRequestException(errorMessage, checkResult);
          }
          default:
            throw new Error(this.genericError);
        }
      } else {
        throw new Error(this.genericError);
      }
    },
  };
};

const dmNoVerifyApiInstance = makeAxionsInsance(dmNoVerifyApi);

let isLoadingNewTokens = false;
export const postRefreshTokenApi = async () => {
  if (isLoadingNewTokens) {
    while (isLoadingNewTokens) {
      await sleep(100);
    }
    if (!getCookieToken()) {
      throw new Error("user timeout");
    }
    return;
  }
  isLoadingNewTokens = true;
  try {
    const auth0Result = await checkSession("postRefreshTokenApi");
    if (auth0Result === null) {
      throw new Error("user timeout");
    }
    const { accessToken, expiresIn } = auth0Result as Auth0DecodedHash;
    if (accessToken === undefined || expiresIn === undefined) {
      // token有効切れの場合はapiコールせずに終了
      throw new Error("user timeout");
    }
    setTokenCookie(accessToken, expiresIn);
  } catch (e) {
    clearAuthCookie();
    await sleep(1000);
    throw e;
  } finally {
    isLoadingNewTokens = false;
  }
};
const sleep = (msec: number) => new Promise((resolve) => setTimeout(resolve, msec));

const dmApiInstance = makeAxionsInsance(dmApi, postRefreshTokenApi);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postReadApi = (requestBody: any) => {
  const instance = { ...dmApiInstance };
  instance.endpoint = `/read`;
  return instance.post(requestBody);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postSaveApi = (requestBody: any) => {
  const instance = { ...dmApiInstance };
  instance.endpoint = `/save`;
  return instance.post(requestBody);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postAuthApi = (requestBody: any, isNoVerify: boolean = true) => {
  const instance = isNoVerify ? { ...dmNoVerifyApiInstance } : { ...dmApiInstance };
  instance.endpoint = `/auth`;
  return instance.post(requestBody);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const postAuthCustomHeaderApi = (requestBody: any, config: AxiosRequestConfig = {}) => {
  const instance = { ...dmNoVerifyApiInstance };
  instance.endpoint = `/auth`;
  return instance.post(requestBody, config);
};
