import { postReadApi } from "./api-base";
import {
  apiUnconfirmedReport,
  portalCareerOrderType,
  accountOrderType,
  portalCareerTabType,
  studyStatusType,
  apiAccountInviteResponse,
  announceTargetType,
  careerType,
  apiLoadInquiryDm,
  apiLoadCategoryList,
  apiLoadCareerList,
  apiLoadThemeList,
  apiLoadCareerThemeList,
  apiLoadContentsList,
  apiLoadContentsViewing,
  apiLoadTest,
  apiLoadMypageCareer,
  apiLoadMypageLegal,
  apiLoadMypageAccountOriginal,
  apiLoadMypageBookmark,
  apiLoadContentsHistory,
  apiLoadMypageFreeContentsAndTutorial,
  apiLoadAccountRequest,
  apiLoadAccountPortalLegal,
  portalLegalOrderType,
  apiLoadAnnounceDtl,
  apiLoadAnnounceAccountReportDtl,
} from "./../../types";
import { getAccountOriginalParams } from "./../../util/common.util";

const readApiBase = (eventType: string, props?: object) => {
  return postReadApi({ eventType, ...props });
};

export const loadCategoryListApi = (): Promise<apiLoadCategoryList[]> => {
  return readApiBase("LOAD_CATEGORY_LIST");
};

export const loadCareerListApi = (): Promise<apiLoadCareerList> => {
  return readApiBase("LOAD_CAREER_LIST");
};

export const loadThemeListApi = (props: {
  accountOriginalFlg: boolean;
  categoryId: number;
}): Promise<apiLoadThemeList> => {
  return readApiBase("LOAD_THEME_LIST", props);
};

export const loadCareerThemeListApi = (props: {
  careerType: careerType;
  level: number;
}): Promise<apiLoadCareerThemeList> => {
  return readApiBase("LOAD_CAREER_THEME_LIST", props);
};

export const loadContentsListApi = (props: {
  accountOriginalFlg: boolean;
  themeId: number;
  // 本来下記2つは必須だが、DMユーザーが質問Boxでコンテンツ変更する際のロードのみ、未指定で現在動作しているため型定義を緩める
  baseYear?: number;
  baseMonth?: number;
}): Promise<apiLoadContentsList> => {
  return readApiBase("LOAD_CONTENTS_LIST", props);
};

export const loadContentsViewingApi = (props: {
  accountOriginalFlg: boolean;
  contentsId: number;
  baseYear: number;
  baseMonth: number;
  packageFlg: boolean;
  packageId: number;
}): Promise<apiLoadContentsViewing> => {
  return readApiBase("LOAD_CONTENTS_VIEWING", props);
};

export const loadTestApi = (props: { accountOriginalFlg: boolean; contentsId: number }): Promise<apiLoadTest> => {
  return readApiBase("LOAD_TEST", props);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const loadTestedApi = (props: { accountOriginalFlg: boolean; contentsId: number }): Promise<any> => {
  return readApiBase("LOAD_TESTED", props);
};

export const loadMypageCareerApi = (): Promise<apiLoadMypageCareer> => {
  return readApiBase("LOAD_MY_PAGE_CAREER");
};

export const loadMypageLegalApi = (props: { baseYear: number; baseMonth: number }): Promise<apiLoadMypageLegal> => {
  return readApiBase("LOAD_MY_PAGE_LEGAL", props);
};

export const loadMypageAccountOriginalApi = (): Promise<apiLoadMypageAccountOriginal> => {
  return readApiBase("LOAD_MY_PAGE_ACCOUNT_ORIGINAL");
};

export const loadMypageBookmarkApi = (): Promise<apiLoadMypageBookmark> => {
  return readApiBase("LOAD_MY_PAGE_BOOKMARK");
};

export const loadContentsHistoryApi = (): Promise<apiLoadContentsHistory> => {
  return readApiBase("LOAD_CONTENTS_HISTORY");
};

export const loadMypageFreeContentsApi = (): Promise<apiLoadMypageFreeContentsAndTutorial> => {
  return readApiBase("LOAD_MY_PAGE_FREE_CONTENTS");
};

export const loadMypageTutorialApi = (): Promise<apiLoadMypageFreeContentsAndTutorial> => {
  return readApiBase("LOAD_MY_PAGE_TUTORIAL");
};

export const loadAccountPortalLegalApi = (props: {
  accountId: string;
  baseYear: number;
  baseMonth: number;
  studyGroupId: number;
  orderType: portalLegalOrderType;
  desc: boolean;
  page: number;
  limit: number;
}): Promise<apiLoadAccountPortalLegal> => {
  return readApiBase("LOAD_ACCOUNT_PORTAL_LEGAL", props);
};

export const loadAccountPortalLegalDtlTermApi = (props: {
  baseYear: number;
  studyGroupType: "ALL" | "SELECT";
  studyGroupId: number;
  fromMonthOrd: number;
  toMonthOrd: number;
  studyStatuses: studyStatusType[];
  accountId?: string;
}) => {
  // accountIdが未定義の場合はaccountIdのkey自体を削除する
  const requestBody = props.accountId
    ? props
    : {
        baseYear: props.baseYear,
        studyGroupType: props.studyGroupType,
        studyGroupId: props.studyGroupId,
        fromMonthOrd: props.fromMonthOrd,
        toMonthOrd: props.toMonthOrd,
        studyStatuses: props.studyStatuses,
      };
  return readApiBase("LOAD_ACCOUNT_PORTAL_LEGAL_DTL_TERM", requestBody);
};

export const loadAccountPortalLegalDtlThemeApi = (props: {
  baseYear: number;
  studyGroupType: "ALL" | "SELECT";
  studyGroupId: number;
  themeId: number;
  studyStatuses: studyStatusType[];
  accountId?: string;
}) => {
  const requestBody = props.accountId
    ? props
    : {
        baseYear: props.baseYear,
        studyGroupType: props.studyGroupType,
        studyGroupId: props.studyGroupId,
        themeId: props.themeId,
        studyStatuses: props.studyStatuses,
      };
  return readApiBase("LOAD_ACCOUNT_PORTAL_LEGAL_DTL_THEME", requestBody);
};

export const loadAccountPortalLegalDtlUserApi = (props: {
  baseYear: number;
  studyGroupId: number;
  userId: number;
  studyStatuses: studyStatusType[];
  accountId?: string;
}) => {
  const requestBody = props.accountId
    ? props
    : {
        baseYear: props.baseYear,
        studyGroupId: props.studyGroupId,
        userId: props.userId,
        studyStatuses: props.studyStatuses,
      };
  return readApiBase("LOAD_ACCOUNT_PORTAL_LEGAL_DTL_USER", requestBody);
};

export const loadAccountPortalCareerApi = (props: {
  tab: portalCareerTabType;
  orderType: portalCareerOrderType;
  desc: boolean;
  page: number;
  limit: number;
  accountId: string;
}) => {
  return readApiBase("LOAD_ACCOUNT_PORTAL_CAREER", props);
};

export const loadAccountPotalFreeContentsApi = (props: { accountId?: string }) => {
  return readApiBase("LOAD_ACCOUNT_PORTAL_FREE_CONTENTS", props);
};

export const loadAccountPotalTutorialApi = (props: { accountId?: string }) => {
  return readApiBase("LOAD_ACCOUNT_PORTAL_TUTORIAL", props);
};

export const loadAccountPortalFreeContentsDtlApi = (props: { contentsId: number; accountId?: string }) => {
  const requestBody = props.accountId ? props : { contentsId: props.contentsId };
  return readApiBase("LOAD_ACCOUNT_PORTAL_FREE_CONTENTS_DTL", requestBody);
};

export const loadAccountPortalTutorialDtlApi = (props: { contentsId: number; accountId?: string }) => {
  const requestBody = props.accountId ? props : { contentsId: props.contentsId };
  return readApiBase("LOAD_ACCOUNT_PORTAL_TUTORIAL_DTL", requestBody);
};

export const loadAccountPortalAccountOriginal = (props: { accountId?: string }) => {
  return readApiBase("LOAD_ACCOUNT_PORTAL_ACCOUNT_ORIGINAL", props);
};

export const loadAccountPortalAccountOriginalContents = (props: { contentsId: number; accountId?: string }) => {
  const requestBody = props.accountId ? props : { contentsId: props.contentsId };
  return readApiBase("LOAD_ACCOUNT_PORTAL_ACCOUNT_ORIGINAL_CONTENTS", requestBody);
};

export const loadStudyStatusUserApi = (props: {
  userId: number;
  accountOriginalFlg: boolean;
  categoryId: number;
  baseYear: number;
  careerType: careerType;
}) => {
  return readApiBase("LOAD_STUDY_STATUS_USER", props);
};

export const loadStudyStatusCareerUserApi = (props: {
  userId: number;
  careerType: careerType;
  level: number;
  baseYear: number;
}) => {
  return readApiBase("LOAD_STUDY_STATUS_CAREER_USER", props);
};

export const loadStudyStatusThemeApi = (props: { userId: number; accountOriginalFlg: boolean; themeId: number }) => {
  return readApiBase("LOAD_STUDY_STATUS_THEME", props);
};

export const loadInquiryUserApi = () => {
  return readApiBase("LOAD_INQUIRY_USER");
};

export const loadInquiryDmApi = (params: apiLoadInquiryDm) => {
  return readApiBase("LOAD_INQUIRY_DM", params);
};

export const loadInquiryOneApi = (props: { inquiryId: number }) => {
  return readApiBase("LOAD_INQUIRY_ONE", props);
};

export const loadInquiryOneDmApi = (props: { inquiryId: number }) => {
  return readApiBase("LOAD_INQUIRY_ONE_DM", props);
};

export const loadInquiryAccountApi = (params: { solved: boolean; page: number; limit: number }) => {
  return readApiBase("LOAD_INQUIRY_ACCOUNT", params);
};

export const loadUserLoginAccountApi = () => {
  return readApiBase("LOAD_USER_LOGIN_ACCOUNT");
};

export const loadUserApi = (props: {
  params: {
    name: string;
    validOnly: boolean;
    orderType: accountOrderType;
    desc: boolean;
    page: number;
    limit: number;
  };
  accountId?: string;
}) => {
  const requestBody = props.accountId ? { ...props.params, accountId: props.accountId } : props.params;
  return readApiBase("LOAD_USER", requestBody);
};

export const loadUserAccountApi = (props: { accountId: string }) => {
  return readApiBase("LOAD_USER_ACCOUNT", props);
};

export const loadCategoryAccountPortalApi = (props: { accountId?: string; userId?: number; baseYear: number }) => {
  return readApiBase("LOAD_CATEGORY_ACCOUNT_PORTAL", props);
};

export const loadCategoryValidStatusApi = (props: { accountOriginalFlg: boolean; accountId?: string }) => {
  return readApiBase("LOAD_CATEGORY_VALID_STATUS", props);
};

export const loadThemeValidStatusApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  categoryId: number;
}) => {
  return readApiBase("LOAD_THEME_VALID_STATUS", props);
};

export const loadAccountApi = (params: apiLoadAccountRequest) => {
  return readApiBase("LOAD_ACCOUNT", params);
};

export const loadAccountOneApi = (props: { accountId?: string }) => {
  return readApiBase("LOAD_ACCOUNT_ONE", props);
};

export const loadAccountAllApi = () => {
  return readApiBase("LOAD_ACCOUNT_ALL");
};

export const loadCorporationAccountApi = (params: {
  name?: string;
  validOnly?: boolean;
  orderType?: accountOrderType;
  desc?: boolean;
  page?: number;
  limit?: number;
}) => {
  return readApiBase("LOAD_CORPORATION_ACCOUNT", params);
};

export const loadCorporationAccountAllApi = () => {
  return readApiBase("LOAD_CORPORATION_ACCOUNT_ALL");
};

export const loadUserQuestionUnsolved = () => {
  return readApiBase("LOAD_USER_QUESTION_UNSOLVED");
};

export const loadCategoryApi = (props: { accountOriginalFlg: boolean; accountId?: string }) => {
  return readApiBase("LOAD_CATEGORY", {
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const loadCategoryGroupApi = () => {
  return readApiBase("LOAD_CATEGORY_GROUP");
};

export const loadThemeApi = (props: { accountOriginalFlg: boolean; accountId?: string }) => {
  return readApiBase("LOAD_THEME", {
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const loadContentsMstListApi = (props: { accountOriginalFlg: boolean; accountId?: string }) => {
  return readApiBase("LOAD_CONTENTS_MST_LIST", {
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const loadContentsMstDtlApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  contentsId: number;
}) => {
  return readApiBase("LOAD_CONTENTS_MST_DTL", {
    contentsId: props.contentsId,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const loadContentsWorkMstDtlApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  contentsWorkId: number;
}) => {
  return readApiBase("LOAD_CONTENTS_WORK_MST_DTL", {
    contentsWorkId: props.contentsWorkId,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const loadContentsMediaListApi = (props: {
  accountOriginalFlg: boolean;
  accountId?: string;
  name: string;
  notSet: boolean;
  page: number;
  limit: number;
}) => {
  return readApiBase("LOAD_CONTENTS_MEDIA_LIST", {
    name: props.name,
    notSet: props.notSet,
    page: props.page,
    limit: props.limit,
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const loadContentsMediaApi = (props: { accountOriginalFlg: boolean; accountId?: string }) => {
  return readApiBase("LOAD_CONTENTS_MEDIA", {
    ...getAccountOriginalParams(props.accountOriginalFlg, props.accountId),
  });
};

export const loadAccountUnconfirmedReportsApi = (props: { accountId: string }): Promise<apiUnconfirmedReport[]> => {
  return readApiBase("LOAD_ACCOUNT_REPORT_UNCONFIRMED", props);
};

export const loadUserUnconfirmedReportsApi = (props: {
  userId: number;
  accountId: string;
}): Promise<apiUnconfirmedReport[]> => {
  return readApiBase("LOAD_USER_REPORT_UNCONFIRMED", props);
};

// id = contentsId
export const loadCustomContentsApi = (props: { contents: { accountOriginalFlg: boolean; id: number }[] }) => {
  return readApiBase("LOAD_CUSTOM_CONTENTS", props);
};

// id = imcompleteReportId
export const loadCustomThemeApi = (props: { themes: { accountOriginalFlg: boolean; id: number }[] }) => {
  return readApiBase("LOAD_CUSTOM_THEME", props);
};

export const loadTestCategoryApi = (props: { testTemplateCategoryId: number }) => {
  return readApiBase("LOAD_TEST_CATEGORY", props);
};

export const loadTestResultCategoryApi = (props: { userId: number | undefined | string }) => {
  return props.userId
    ? readApiBase("LOAD_TEST_RESULT_CATEGORY", { userId: props.userId })
    : readApiBase("LOAD_TEST_RESULT_CATEGORY");
};

export const loadTestResultCategoryDtlApi = (props: { testResultCategoryId: number }) => {
  return readApiBase("LOAD_TEST_RESULT_CATEGORY_DTL", props);
};

export const loadStudyGroupApi = (props: { accountId?: string }) => {
  return props.accountId
    ? readApiBase("LOAD_STUDY_GROUP", { accountId: props.accountId })
    : readApiBase("LOAD_STUDY_GROUP");
};

export const loadLegalCurriculumMstListApi = (props: { accountId?: string }) => {
  return props.accountId
    ? readApiBase("LOAD_LEGAL_CURRICULUM_MST_LIST", { accountId: props.accountId })
    : readApiBase("LOAD_LEGAL_CURRICULUM_MST_LIST");
};

export const loadLegalCurriculumMstApi = (props: { id: number }) => {
  return readApiBase("LOAD_LEGAL_CURRICULUM_MST", props);
};

export const loadLegalCurriculumTemplateApi = () => {
  return readApiBase("LOAD_LEGAL_CURRICULUM_TEMPLATE");
};

export const loadLegalCurriculumListApi = (props: { baseYear: number }) => {
  return readApiBase("LOAD_LEGAL_CURRICULUM_LIST", props);
};

export const loadLegalCurriculumThemeApi = (props: { baseYear: number }) => {
  return readApiBase("LOAD_LEGAL_CURRICULUM_THEME", props);
};

export const loadLegalCurriculumYearApi = (props: { accountId?: string }) => {
  return readApiBase("LOAD_LEGAL_CURRICULUM_YEAR", props);
};

export const loadCareerExclusionApi = (props: { studyGroupId: number; accountId?: string }) => {
  return readApiBase("LOAD_CAREER_EXCLUSION", props);
};

export const loadAnnounceHomeApi = () => {
  return readApiBase("LOAD_ANNOUNCE_HOME");
};

export const loadAnnounceListApi = (props: { unreadOnly: boolean; page: number; limit: number }) => {
  return readApiBase("LOAD_ANNOUNCE_LIST", props);
};

export const loadAnnounceDtlApi = (props: {
  id: number;
  targetType: announceTargetType;
}): Promise<apiLoadAnnounceDtl | apiLoadAnnounceAccountReportDtl> => {
  return readApiBase("LOAD_ANNOUNCE_DTL", props);
};

export const loadAnnounceMstListApi = (props: { page: number; limit: number }) => {
  return readApiBase("LOAD_ANNOUNCE_MST_LIST", props);
};

export const loadAnnounceMstDtlApi = (props: { id: number }) => {
  return readApiBase("LOAD_ANNOUNCE_MST_DTL", props);
};

export const loadCategoryGenreApi = () => {
  return readApiBase("LOAD_CATEGORY_GENRE");
};

export const loadNextActionApi = (props: { contentsId: number; baseYear: number; baseMonth: number }) => {
  return readApiBase("LOAD_NEXT_ACTION", props);
};

export const loadUserAdditionalInfoApi = () => {
  return readApiBase("LOAD_USER_INFO");
};

export const loadInitialTutorialApi = () => {
  return readApiBase("LOAD_INITIAL_TUTORIAL");
};

export const loadInitialTutorialProgressApi = () => {
  return readApiBase("LOAD_INITIAL_TUTORIAL_PROGRESS");
};

export const loadImportUserBulkHistoryApi = (props: { accountId?: string }) => {
  return props.accountId
    ? readApiBase("LOAD_USER_BULK_HISTORY", { accountId: props.accountId })
    : readApiBase("LOAD_USER_BULK_HISTORY");
};

export const loadAccountInviteApi = (props: { accountId: string }): Promise<apiAccountInviteResponse | null> => {
  return readApiBase("LOAD_ACCOUNT_INVITE", props);
};

export const loadCareerHistoryApi = () => {
  return readApiBase("LOAD_CAREER_HISTORY");
};

export const loadCareerCertificateOfCompletionApi = (props: { careerType: careerType; level: number }) => {
  return readApiBase("LOAD_CAREER_CERTIFICATE_OF_COMPLETION", props);
};

export const loadCareerThemeApi = () => {
  const requestBody = {
    eventType: "LOAD_CAREER_THEME",
  };
  return postReadApi(requestBody);
};

export const loadBreadcrumb = (props: { accountOriginalFlg: boolean; themeId: number; contentsId?: number }) => {
  return readApiBase("LOAD_BREADCRUMB", props);
};

export const loadAccountByCorporationAccountApi = (props: { accountId: string }) => {
  return readApiBase("LOAD_ACCOUNT_BY_CORPORATION_ACCOUNT", props);
};

export const loadStudyGroupByAccountIdApi = (props: { accountId: string }) => {
  return readApiBase("LOAD_STUDY_GROUP_BY_ACCOUNT_ID", props);
};

export const loadLegalCurriculumDocumentStatus = (props: { legalCurriculumId: number }) => {
  return readApiBase("LOAD_LEGAL_CURRICULUM_DOCUMENT_STATUS", props);
};

export const loadContentsBaseViewingApi = () => {
  return readApiBase("LOAD_CONTENTS_BASE_VIEWING");
};

export const loadContentsApi = (props: { name: string }) => {
  return readApiBase("LOAD_CONTENTS", props);
};

export const loadPackageAccountApi = (props: { accountId: string; includePrivate: boolean }) => {
  return readApiBase("LOAD_PACKAGE_ACCOUNT", props);
};

export const loadPackageListApi = (props: { includePrivate: boolean }) => {
  return readApiBase("LOAD_PACKAGE_LIST", props);
};

export const loadPackageOneApi = (props: { packageId: number }) => {
  return readApiBase("LOAD_PACKAGE_ONE", props);
};

export const loadMypagePackageApi = () => {
  return readApiBase("LOAD_MY_PAGE_PACKAGE");
};

export const loadMypagePackageDtlApi = (props: { packageId: number }) => {
  return readApiBase("LOAD_MY_PAGE_PACKAGE_DTL", props);
};

export const loadAccountPortalPackageApi = (props: { accountId?: string }) => {
  return readApiBase("LOAD_ACCOUNT_PORTAL_PACKAGE", props);
};

export const loadAccountPortalPackageDtlApi = (props: { packageId: number; accountId?: string }) => {
  return readApiBase("LOAD_ACCOUNT_PORTAL_PACKAGE_DTL", props);
};

export const loadCorporationAccountForSearchApi = () => {
  return readApiBase("LOAD_CORPORATION_ACCOUNT_FOR_SEARCH");
};

export const loadPackageForSearchApi = (props: { includePrivate: boolean }) => {
  return readApiBase("LOAD_PACKAGE_FOR_SEARCH", props);
};

export const loadMypageLegalGroupStudyApi = (props: { studyGroupId: number; baseYear: number; baseMonth: number }) => {
  return readApiBase("LOAD_MY_PAGE_LEGAL_GROUP_STUDY", props);
};

export const loadGroupStudyUserApi = (props: {
  contentsId: number;
  accountOriginalFlg: boolean;
  baseYear: number;
  baseMonth: number;
  packageFlg: boolean;
  packageId: number;
}) => {
  return readApiBase("LOAD_GROUP_STUDY_USER", props);
};

export const loadGroupStudiedUserApi = (props: { accountOriginalFlg: boolean; groupStudyHistoryId: number }) => {
  return readApiBase("LOAD_GROUP_STUDIED_USER", props);
};

export const loadGroupStudyContentsViewingApi = (props: {
  contentsId: number;
  accountOriginalFlg: boolean;
  baseYear: number;
  baseMonth: number;
  packageFlg: boolean;
  packageId: number;
}) => {
  return readApiBase("LOAD_GROUP_STUDY_CONTENTS_VIEWING", props);
};

export const loadGroupStudyAndStudiedUserApi = (props: {
  accountOriginalFlg: boolean;
  groupStudyHistoryId: number;
}) => {
  return readApiBase("LOAD_GROUP_STUDY_AND_STUDIED_USER", props);
};

export const loadAccountPortalGroupStudyApi = (props: { page: number; limit: number; accountId?: string }) => {
  return readApiBase("LOAD_ACCOUNT_PORTAL_GROUP_STUDY", props);
};

export const loadGroupStudyUlidApi = (props: { groupStudyHistoryId: number; accountOriginalFlg: boolean }) => {
  return readApiBase("LOAD_GROUP_STUDY_ULID", props);
};

export const loadCanReportApi = (props: { accountOriginalFlg: boolean; themeId: number }) => {
  return readApiBase("LOAD_CAN_REPORT", props);
};

export const loadUserBulkWorkApi = (props: { accountId?: string }) => {
  const requestBody = props.accountId ? { accountId: props.accountId } : {};
  return readApiBase("LOAD_USER_BULK_WORK", requestBody);
};
