import React, { createContext, useReducer, useContext } from "react";
import { childrenProps } from "../types";

type loadingInfo = {
  isLoading: boolean;
};

const initialState = {
  isLoading: false,
} as loadingInfo;

const LoaderContext = createContext(
  {} as {
    contextData: loadingInfo;
  }
);
const LoaderDispatchContext = createContext(
  {} as {
    dispatch: React.Dispatch<action>;
  }
);

type actionType = "SHOW_LOADING" | "HIDE";
type action = {
  type: actionType;
};

export const showLoading = (): action => {
  return { type: "SHOW_LOADING" };
};

export const hideLoading = (): action => {
  return { type: "HIDE" };
};

const loaderReducer = (state: loadingInfo, action: action): loadingInfo => {
  switch (action.type) {
    case "SHOW_LOADING":
      return { isLoading: true };
    case "HIDE":
      return { isLoading: false };
    default:
      return state;
  }
};

export const LoaderProvider: React.FC<childrenProps> = (props: childrenProps) => {
  const [contextData, dispatch] = useReducer(loaderReducer, initialState);
  const loaderContext = React.useMemo(() => ({ contextData }), [contextData]);
  const loaderDispatch = React.useMemo(() => ({ dispatch }), []);
  return (
    <LoaderContext.Provider value={loaderContext}>
      <LoaderDispatchContext.Provider value={loaderDispatch} {...props} />
    </LoaderContext.Provider>
  );
};

export const useLoaderContext = () => {
  return useContext(LoaderContext);
};

export const useLoaderDispatchContext = () => {
  return useContext(LoaderDispatchContext);
};
