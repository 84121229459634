import React, { createContext, useContext, useMemo, useReducer } from "react";
import { childrenProps, legalCurriculumPdfParamType, legalCurriculumResultPdfParamType } from "../types";
import { v4 as uuidv4 } from "uuid";

export type reportType = "LEGAL_CURRICULUM" | "LEGAL_CURRICULUM_RESULT";
export type reportStatusType = "SUCCESS" | "ERROR" | "PROCESSING";
export type conditionInfoType = { key: string; label: string; value: string | number | string[] };

type pdfInfoBase = {
  id: string;
  // 出力帳票名
  title: string;
  fileName: string;
  // 出力条件に関する情報を表示
  conditionInfos: conditionInfoType[];
  status: reportStatusType;
  startDate?: Date;
  endDate?: Date;
  blobUrl?: string;
  blob?: Blob;
};

// 帳票ごとの個別定義
type legalCurriculumReportType = {
  reportType: "LEGAL_CURRICULUM";
  reportDataFunc: () => Promise<legalCurriculumPdfParamType>;
};
type legalCurriculumPdfInfo = pdfInfoBase & legalCurriculumReportType;

type legalCurriculumResultReportType = {
  reportType: "LEGAL_CURRICULUM_RESULT";
  reportDataFunc: () => Promise<legalCurriculumResultPdfParamType>;
};
type legalCurriculumResultPdfInfo = pdfInfoBase & legalCurriculumResultReportType;

export type reportLogic = legalCurriculumReportType | legalCurriculumResultReportType;
export type reportDataType =
  | { reportType: "LEGAL_CURRICULUM"; reportData: legalCurriculumPdfParamType }
  | { reportType: "LEGAL_CURRICULUM_RESULT"; reportData: legalCurriculumResultPdfParamType };

export type pdfInfoType = legalCurriculumPdfInfo | legalCurriculumResultPdfInfo;

export type PdfInfoList = pdfInfoType[];

const initialState = [] as PdfInfoList;

const PdfContext = createContext(
  {} as {
    pdfContext: PdfInfoList;
  }
);

export type PdfDispatch = React.Dispatch<action>;
type removePdfAction = {
  type: "REMOVE_PDF";
  id: string;
};
type clearAllPdfAction = {
  type: "CLEAR_ALL_PDF";
};

type setErrorAction = {
  type: "SET_ERROR";
  id: string;
  status: "ERROR";
  endDate: Date;
};

type setSuccessAction = {
  type: "SET_SUCCESS";
  id: string;
  status: "SUCCESS";
  endDate: Date;
  blobUrl: string;
  blob: Blob;
};
type addLegalCurriculumPdfAction = {
  type: "ADD_PDF";
  id: string;
  // 出力帳票名
  title: string;
  fileName: string;
  // 出力条件に関する情報を表示
  conditionInfos: conditionInfoType[];
  // 帳票のデータ習得
  reportDataFunc: () => Promise<legalCurriculumPdfParamType>;
  // 帳票の種類
  reportType: "LEGAL_CURRICULUM";
  status: "PROCESSING";
  startDate: Date;
};

type addLegalCurriculumResultPdfAction = {
  type: "ADD_PDF";
  id: string;
  // 出力帳票名
  title: string;
  fileName: string;
  // 出力条件に関する情報を表示
  conditionInfos: conditionInfoType[];
  // 帳票のデータ
  reportDataFunc: () => Promise<legalCurriculumResultPdfParamType>;
  // 帳票の種類
  reportType: "LEGAL_CURRICULUM_RESULT";
  status: "PROCESSING";
  startDate: Date;
};

type action =
  | removePdfAction
  | clearAllPdfAction
  | setErrorAction
  | setSuccessAction
  | addLegalCurriculumPdfAction
  | addLegalCurriculumResultPdfAction;

const PdfDispatchContext = createContext(
  {} as {
    dispatch: PdfDispatch;
  }
);

export const addLegalCurriculumPdf = (
  title: string,
  fileName: string,
  conditionInfos: conditionInfoType[],
  reportDataFunc: () => Promise<legalCurriculumPdfParamType>
): action => {
  return {
    type: "ADD_PDF",
    id: uuidv4(),
    title,
    fileName,
    conditionInfos,
    reportType: "LEGAL_CURRICULUM",
    reportDataFunc,
    status: "PROCESSING",
    startDate: new Date(),
  };
};

export const addLegalCurriculumResultPdf = (
  title: string,
  fileName: string,
  conditionInfos: conditionInfoType[],
  reportDataFunc: () => Promise<legalCurriculumResultPdfParamType>
): action => {
  return {
    type: "ADD_PDF",
    id: uuidv4(),
    title,
    fileName,
    conditionInfos,
    reportType: "LEGAL_CURRICULUM_RESULT",
    reportDataFunc,
    status: "PROCESSING",
    startDate: new Date(),
  };
};

export const removePdf = (id: string): action => {
  return {
    type: "REMOVE_PDF",
    id,
  };
};

export const clearAllPdf = (): action => {
  return {
    type: "CLEAR_ALL_PDF",
  };
};

export const setError = (id: string): action => {
  return {
    type: "SET_ERROR",
    id,
    status: "ERROR",
    endDate: new Date(),
  };
};

export const setSuccess = (id: string, blobUrl: string, blob: Blob): action => {
  return {
    type: "SET_SUCCESS",
    id,
    status: "SUCCESS",
    endDate: new Date(),
    blobUrl,
    blob,
  };
};

const pdfReducer = (state: PdfInfoList, action: action): PdfInfoList => {
  switch (action.type) {
    case "ADD_PDF":
      return [...state, action as pdfInfoType];
    case "REMOVE_PDF":
      return state.filter((item) => item.id != action.id);
    case "CLEAR_ALL_PDF":
      return [];
    case "SET_ERROR":
      return state.map((item) => {
        if (item.id == action.id) {
          return {
            ...item,
            status: action.status || "ERROR",
            endDate: action.endDate,
          };
        }
        return item;
      });
    case "SET_SUCCESS":
      return state.map((item) => {
        if (item.id == action.id) {
          return {
            ...item,
            status: action.status || "ERROR",
            endDate: action.endDate,
            blobUrl: action.blobUrl,
          };
        }
        return item;
      });
    default:
      return state;
  }
};

export const PdfProvider = (props: childrenProps) => {
  const [contextData, dispatch] = useReducer(pdfReducer, initialState);
  const pdfContext = useMemo(
    () => ({
      pdfContext: contextData,
    }),
    [contextData]
  );
  const pdfDispatch = useMemo(
    () => ({
      dispatch,
    }),
    []
  );

  return (
    <PdfContext.Provider value={pdfContext}>
      <PdfDispatchContext.Provider value={pdfDispatch} {...props} />
    </PdfContext.Provider>
  );
};

export const usePdfContext = () => {
  return useContext(PdfContext);
};

export const usePdfDispatch = () => {
  return useContext(PdfDispatchContext);
};
