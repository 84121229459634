import React, { createContext, useReducer, useContext, useMemo } from "react";
import { NavigateFunction } from "react-router-dom";
import { Header } from "semantic-ui-react";

import { ContentItem, CustomThemeReportItem } from "../components/Custom";

import { apiCustomContentsDtl, apiCustomThemeDtl, childrenProps, contentsStatus, deviceType } from "../types";
import {
  getContentPagePath,
  getContentTestPagePath,
  undefinedToBoolean,
  undefinedToNumber,
  undefinedToString,
} from "../util/common.util";

type CustomListInfo<T> = {
  hasData: boolean;
  headerTitle: string;
  dataList: T[];
  useWide: boolean;
  usePcDivided?: boolean;
  useSpDivided?: boolean;
  useGroup?: boolean;
  useStatus?: boolean;
  // リストで表示される1行のコンポーネント
  getItemContent: (item: T, deviceType: deviceType, navigate: NavigateFunction, useStatus?: boolean) => JSX.Element;
  // dataListの中からgroupingする際に判定に利用する数字 または 文字列
  getGroupKey?: (item: T) => number | string;
  // グルーピング表示するヘッダー行のコンポーネント
  getGroupContent?: (item: T, deviceType: deviceType, navigate: NavigateFunction) => string | JSX.Element;
};
const initialState = {
  hasData: false,
  headerTitle: "表示データが見つかりませんでした。",
  dataList: [],
  useWide: false,
  getItemContent: () => {
    return <div>データがありません</div>;
  },
} as CustomListInfo<string>;

const CustomListContext = createContext(
  {} as {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    customListContext: CustomListInfo<any>;
  }
);

const CustomListDispatchContext = createContext(
  {} as {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dispatch: CustomListDispatch<any>;
  }
);

export type CustomListDispatch<T> = React.Dispatch<action<T>>;

type actionType = "OPEN_CONTENTS" | "OPEN_DM_REPORTS" | "OPEN_USER_REPORTS" | "CLOSE_CUSTOM_LIST";
type action<T> = { type: actionType; customListInfo?: CustomListInfo<T> };

export type dummyItem = {
  themeId: number;
  id: number;
  title: string;
  themeDescription: string;
  header: string;
  contents: string;
};

export const closeCustomList = (): action<string> => {
  return {
    type: "CLOSE_CUSTOM_LIST",
  };
};

export const openContents = (
  data: apiCustomContentsDtl[],
  title: string,
  useStatus?: boolean
): action<apiCustomContentsDtl> => {
  // api側でソートがかからないので、ここでまとめてソート
  const compareOrd = (a: number, b: number) => {
    return a === b ? 0 : a < b ? -1 : 1;
  };
  const sortedData = Array.from(data).sort((a, b) => {
    if (a.theme.id === b.theme.id) {
      return compareOrd(a.contents.ord, b.contents.ord);
    } else {
      return compareOrd(a.theme.ord, b.theme.ord);
    }
  });
  const getItemContent = (
    item: apiCustomContentsDtl,
    deviceType: deviceType,
    navigate: NavigateFunction,
    useStatus?: boolean
  ) => {
    const contentsTrasition = () => {
      navigate(getContentPagePath(item.contents.id, item.contents.categoryAccountOriginalId != undefined));
    };
    const contentsTestTrasition = () => {
      navigate(getContentTestPagePath(item.contents.id, item.contents.categoryAccountOriginalId != undefined));
    };
    return (
      <ContentItem
        id={item.contents.id}
        name={item.contents.name}
        image={item.contents.image}
        description={item.contents.description}
        contentsStatus={undefinedToString(item.contents.historyContentsStatus, "NEW") as contentsStatus}
        requiredTest={item.contents.requiredTest}
        contentsTrasition={contentsTrasition}
        deviceType={deviceType}
        viewCount={undefinedToNumber(item.contents.viewCount, 0)}
        existTest={undefinedToBoolean(item.contents.existTest, false)}
        contentsTestTrasition={contentsTestTrasition}
        categoryName={item.category !== null ? item.category.name : item.career.name}
        themeName={item.theme.name}
        testPass={item.contents.testPass}
        useRecomendTest={true}
        playTime={item.contents.playtime}
        useStatus={useStatus}
        // ドリルダウン側でdivideをコントロールするために固定でlengthとindexを入れる
        length={1}
        index={0}
      />
    );
  };
  // const getGroupKey = (item: apiCustomContentsDtl) => {
  //   return item.theme.id;
  // };
  // const getGroupContent = (item: apiCustomContentsDtl) => {
  //   // 本来はキャッシュから取得したテーマ情報でテーマの各情報をバインドする
  //   return <Header content={item.theme.name} as="h3" />;
  //   // return <Label color="blue" content={item.title} />;
  // };
  return {
    type: "OPEN_CONTENTS",
    customListInfo: {
      hasData: true,
      headerTitle: title,
      dataList: sortedData,
      useWide: false,
      usePcDivided: true,
      useSpDivided: false,
      // リストで表示される1行のコンポーネント
      getItemContent: getItemContent,
      // テーマ名でのグルーピングはしない
      useGroup: false,
      // dataListの中からgroupingする際に判定に利用する数字 または 文字列
      // getGroupKey: getGroupKey,
      // グルーピング表示するヘッダー行のコンポーネント
      // getGroupContent: getGroupContent,
      useStatus: useStatus,
    },
  };
};

export const openDmReports = (datas: apiCustomThemeDtl[], title: string): action<apiCustomThemeDtl> => {
  const getItemContent = (item: apiCustomThemeDtl, deviceType: deviceType) => {
    return (
      <CustomThemeReportItem
        id={item.report.id}
        categoryId={item.theme.categoryId}
        themeId={item.theme.id}
        categoryAccountOriginalId={item.theme.categoryAccountOriginalId}
        themeAccountOriginalId={item.theme.id}
        userId={item.user.id}
        report={item.report.report}
        status={item.report.status}
        themeName={item.theme.name}
        themeDescription={item.theme.description}
        themeImage={item.theme.image}
        deviceType={deviceType}
      />
    );
  };
  const getGroupKey = (item: apiCustomThemeDtl) => {
    return item.user.id;
  };
  const getGroupContent = (item: apiCustomThemeDtl) => {
    // 本来はキャッシュから取得したテーマ情報でテーマの各情報をバインドする
    return (
      <Header
        content={`${item.user.lastName} ${item.user.firstName}`}
        as="h3"
        style={{ padding: "0rem 0rem 0rem 0.5rem" }}
      />
    );
  };
  return {
    type: "OPEN_DM_REPORTS",
    customListInfo: {
      hasData: true,
      headerTitle: title,
      dataList: datas,
      useWide: true,
      // リストで表示される1行のコンポーネント
      getItemContent: getItemContent,
      // テーマ名でのグルーピングはしない
      useGroup: true,
      getGroupKey: getGroupKey,
      getGroupContent: getGroupContent,
    },
  };
};

export const openDmUserReports = (datas: apiCustomThemeDtl[], title: string): action<apiCustomThemeDtl> => {
  const getItemContent = (item: apiCustomThemeDtl, deviceType: deviceType) => {
    return (
      <CustomThemeReportItem
        id={item.report.id}
        categoryId={item.theme.categoryId}
        themeId={item.theme.id}
        categoryAccountOriginalId={item.theme.categoryAccountOriginalId}
        themeAccountOriginalId={item.theme.id}
        userId={item.user.id}
        report={item.report.report}
        status={item.report.status}
        themeName={item.theme.name}
        themeDescription={item.theme.description}
        themeImage={item.theme.image}
        deviceType={deviceType}
      />
    );
  };
  const getGroupKey = (item: apiCustomThemeDtl) => {
    return item.user.id;
  };
  const getGroupContent = (item: apiCustomThemeDtl) => {
    // 本来はキャッシュから取得したテーマ情報でテーマの各情報をバインドする
    return (
      <Header
        content={`${item.user.lastName} ${item.user.firstName}`}
        as="h3"
        style={{ padding: "0rem 0rem 0rem 0.5rem" }}
      />
    );
  };
  return {
    type: "OPEN_USER_REPORTS",
    customListInfo: {
      hasData: true,
      headerTitle: title,
      dataList: datas,
      useWide: true,
      // リストで表示される1行のコンポーネント
      getItemContent: getItemContent,
      // テーマ名でのグルーピングはしない
      useGroup: true,
      getGroupKey: getGroupKey,
      getGroupContent: getGroupContent,
    },
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const customListReducer = (state: CustomListInfo<any>, action: action<any>): CustomListInfo<any> => {
  switch (action.type) {
    case "OPEN_CONTENTS":
      return {
        ...state,
        ...action.customListInfo,
      };
    case "OPEN_DM_REPORTS":
      return {
        ...state,
        ...action.customListInfo,
      };
    case "OPEN_USER_REPORTS":
      return {
        ...state,
        ...action.customListInfo,
      };
    case "CLOSE_CUSTOM_LIST":
      return initialState;
    default:
      return state;
  }
};

export const CustomListProvider = (props: childrenProps) => {
  const [contextData, dispatch] = useReducer(customListReducer, initialState);
  const customListContext = useMemo(
    () => ({
      customListContext: contextData,
    }),
    [contextData]
  );

  const customListDispatch = useMemo(
    () => ({
      dispatch,
    }),
    []
  );

  return (
    <CustomListContext.Provider value={customListContext}>
      <CustomListDispatchContext.Provider value={customListDispatch} {...props} />
    </CustomListContext.Provider>
  );
};

export const useCustomListContext = () => {
  return useContext(CustomListContext);
};

export const useCustomListDispatch = () => {
  return useContext(CustomListDispatchContext);
};
